<script>
  import { onMount } from "svelte";
  import { mx } from "$lib/utils";
  import Field from "../Field.svelte";

  let { form, name, fieldProps, ...props } = $props();
  let textarea = null;

  let propsMx = $derived(
    mx(
      {
        id: name,
        name,
        class:
          "min-h-12 block w-full flex-1 appearance-none bg-transparent px-4 py-3 placeholder:text-st-500 autofill:shadow-[inset_0_0_0px_1000px_rgba(var(--colors-st-200))] focus:outline-none focus:ring-0 disabled:text-st-400",
      },
      props,
    ),
  );

  const update = () => {
    textarea.style.height = 0;
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  onMount(update);
</script>

<svelte:window onresize={update} />

<Field {form} {name} {...fieldProps} containerProps={{ class: "block divide-y divide-st-300 p-0" }} noLabel>
  <textarea bind:this={textarea} bind:value={$form[name]} oninput={update} {...propsMx}></textarea>
</Field>
