<script>
  import { Image } from "@unpic/svelte";
  import { mx } from "$lib/utils";

  const IMAGES = import.meta.glob("$assets/images/templates/*.jpg", { eager: true });

  let { template, ...props } = $props();
</script>

<Image
  src={IMAGES[`/images/templates/${template}.jpg`].default}
  {...mx({ alt: template, width: 613, aspectRatio: "3/2", class: "w-full rounded-lg shadow-xl transition-all group-hover:brightness-125 group-active:brightness-125" }, props)}
/>
