<script>
  import { cn, mx } from "$lib/utils";
  import Link from "$lib/components/Link.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { text, iconProps, ...props } = $props();
</script>

<Link
  {...mx(
    {
      class: cn(
        "flex h-12 w-full items-center justify-center gap-2 rounded-md bg-st-500/70 text-white shadow-2xl backdrop-blur-md transition-colors hover:bg-st-500 disabled:text-white/50 disabled:hover:bg-st-500/70",
      ),
    },
    props,
  )}
>
  <Icon {...mx({ class: "size-3.5" }, iconProps)} />
  <span>{text}</span>
</Link>
