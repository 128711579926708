<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";
  import RichTextField from "$lib/components/form/fields/RichTextField.svelte";

  let { product, product_type_options, album_options, music_ids } = $props();

  const form = useForm(product);

  let isMusic = $derived(music_ids.includes($form.product_type_id));

  $effect(() => {
    if (!isMusic && $form.album_id !== "") $form.album_id = "";
  });
</script>

<FormModal {form} title="Edit Product" action={`/merch/${product.id}`} method="patch">
  <ImageField {form} name="image" required />
  <InputField {form} name="name" placeholder="Product Name" required />
  <InputField {form} name="slug" type="slug" required />
  <SelectField {form} name="product_type_id" options={product_type_options} fieldProps={{ label: "Type" }} />

  {#if isMusic}
    <SelectField {form} name="album_id" options={album_options} />
  {/if}

  <InputField {form} name="released_on" type="date" fieldProps={{ label: "Release date" }} required />
  <InputField {form} name="upc" type="upc" />
  <RichTextField {form} name="info" placeholder="Add a description, credits, accolades, etc." />
</FormModal>
