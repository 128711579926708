<script>
  import { Chart, LineController, LineElement, PointElement, CategoryScale, LinearScale, Tooltip } from "chart.js";
  import { commaNumber } from "$lib/utils";

  Chart.register(CategoryScale, LinearScale, LineController, LineElement, PointElement, Tooltip);

  let { data } = $props();
  let canvas = $state();
  let chart = $state();

  let config = $derived({
    type: "line",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      elements: {
        line: {
          borderWidth: 1,
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      plugins: {
        tooltip: {
          boxWidth: 8,
          boxHeight: 8,
          boxPadding: 4,
          usePointStyle: true,
          padding: 16,
          titleFont: { family: "ibm-plex-mono" },
          bodyFont: { family: "ibm-plex-mono" },
          itemSort: (a, b) => b.raw - a.raw,
          callbacks: {
            title: (context) => context[0].label.toUpperCase(),
            label: (context) => `${context.dataset.label.toUpperCase()}: ${commaNumber(context.parsed.y)}`,
          },
        },
      },
      scales: {
        x: {
          border: { display: false },
          grid: { display: false },
          ticks: {
            color: () => (document.documentElement.classList.contains("dark") ? "#a3a3a3" : "#0a0a0a"),
            padding: 12,
            maxTicksLimit: () => (window.innerWidth > 640 ? 7 : 4),
            font: { family: "ibm-plex-mono" },
          },
        },
        y: {
          border: { display: false },
          grid: { display: false },
          stacked: true,
          ticks: {
            color: "#a3a3a3",
            padding: 12,
            maxTicksLimit: () => (window.innerWidth > 640 ? 6 : 4),
            font: { family: "ibm-plex-mono" },
          },
        },
      },
    },
  });

  $effect(() => {
    chart = new Chart(canvas, config);
    return () => chart.destroy();
  });
</script>

<canvas bind:this={canvas}></canvas>
