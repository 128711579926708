<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { router, useForm } from "@inertiajs/svelte";
  import Form from "./Form.svelte";
  import Fieldset from "$lib/components/form/Fieldset.svelte";
  import LinkField from "$lib/components/form/fields/LinkField.svelte";
  import ImageField from "$lib/components/form/fields/ImageField.svelte";
  import ColorField from "$lib/components/form/fields/ColorField.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Menu from "$lib/components/Menu.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { design, fonts } = $props();

  const form = $derived(
    useForm({
      template_id: design.template_id,
      cover: design.images.cover,
      logo: design.images.logo,
      background: design.colors.background,
      foreground: design.colors.foreground,
    }),
  );

  const transformData = (data) => {
    const { cover, logo, background, foreground, ...rest } = data;
    return { design: { images: { cover, logo }, colors: { background, foreground }, ...rest } };
  };

  const onLinkSort = async (target, position) => {
    router.patch(`/design/links/${target}/position`, { position });
  };
</script>

<Form {form} {transformData} autoSubmit containerProps={{ class: "space-y-8" }}>
  <Fieldset legend="Design">
    <LinkField {form} name="template_id" href="/design/templates" />
  </Fieldset>

  <Fieldset legend="Images">
    <ImageField {form} name="cover" />
    <ImageField {form} name="logo" isSVG />
  </Fieldset>

  <Fieldset legend="Colors">
    <ColorField {form} name="background" />
    <ColorField {form} name="foreground" />
  </Fieldset>

  <Fieldset legend="Fonts">
    {#each Object.entries(design.fonts) as [name, value]}
      <LinkField {form} {name} href="/design/fonts/{name}">
        <span class={`font-${value}`}>{fonts[value]}</span>
      </LinkField>
    {/each}
  </Fieldset>

  <Fieldset legend="Header">
    <StackList onsort={design.header_sortable ? (ids) => onLinkSort("header", ids) : undefined}>
      {#each design.header_links as { id, name, hidden, actions } (id)}
        <StackListItem data-id={id}>
          {#snippet title()}
            <span class:opacity-30={hidden}>{name}</span>
          {/snippet}

          {#snippet action()}
            <Menu items={actions} />
          {/snippet}
        </StackListItem>
      {/each}
    </StackList>
  </Fieldset>

  <Fieldset legend="Footer">
    <StackList onsort={(ids) => onLinkSort("footer", ids)}>
      {#each design.footer_links as { id, name, icon, actions } (id)}
        <StackListItem data-id={id}>
          {#snippet image()}
            <Icon type={icon} class="size-4" />
          {/snippet}

          {#snippet title()}
            {name}
          {/snippet}

          {#snippet action()}
            <Menu items={actions} />
          {/snippet}
        </StackListItem>
      {/each}
    </StackList>

    <Link href="/design/links/new" class="block rounded-md bg-st-950/[.03] px-4 py-3 text-center transition-colors hover:bg-accent hover:text-white">
      <Icon type="plus" class="size-4" />
    </Link>
  </Fieldset>
</Form>
