<script>
  import { Image } from "@unpic/svelte";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { album } = $props();
</script>

<Table title="Album">
  <TableRow actions={[{ text: "View", href: album.show_href, iconProps: { type: "arrow-right" } }]}>
    {#snippet colOne()}
      <div class="flex items-center gap-4">
        <Image src={album.image_url} alt={album.name} width={32} height={32} class="rounded" />
        <span class="font-sans text-base">{album.name}</span>
      </div>
    {/snippet}

    {#snippet colTwo()}
      {album.type}
    {/snippet}

    {#snippet colThree()}
      <div class="flex items-center gap-2">
        <Badge size="sm">UPC</Badge>
        <span class="text-sm uppercase">{album.upc || "Unknown"}</span>
      </div>
    {/snippet}
  </TableRow>
</Table>
