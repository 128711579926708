<script>
  import { Image } from "@unpic/svelte";
  import { mx, getMeta } from "$lib/utils";
  import Field from "../Field.svelte";
  import Icon from "../../Icon.svelte";
  import ProgressSpinner from "../../ProgressSpinner.svelte";
  import SVG from "../../SVG.svelte";

  let { form, name, required = false, isSVG = false, fieldProps, ...props } = $props();

  let attr = $derived(isSVG ? "body" : "cloudflare_id");
  let value = $derived($form[name]?.[attr]);
  let url = $derived(value && !isSVG ? `${cfUrlRoot}/${value}` : null);

  let accept = $derived(isSVG ? "image/svg+xml" : "image/gif,image/jpeg,image/png");
  let maxSize = $derived(isSVG ? 1_048_576 : 10_485_760);
  let hint = $derived(isSVG ? "Accepts an SVG under 1 MB" : "Accepts a JPG, GIF, or PNG under 10 MB");

  let cfUrlRoot = $state("");
  let csrfParam = $state("");
  let csrfToken = $state("");

  let fileInputRef = $state();

  let isDropping = $state(false);
  let isWorking = $state(false);

  const update = (val) => {
    $form[name] = { [attr]: val };
    fileInputRef.form.dispatchEvent(new Event("change"));
  };

  const reset = () => {
    update(null);
  };

  const error = (message) => {
    $form.setError(name, message);
  };

  const validate = (file) => {
    $form.clearErrors(name);

    if (!file) error("not selected");
    if (!accept.includes(file.type)) error("type is invalid");
    if (file.size > maxSize) error("is too large");

    return $form.errors[name] === undefined;
  };

  const upload = async (file) => {
    isDropping = false;
    if (!validate(file)) return;

    isWorking = true;
    isSVG ? await uploadSvg(file) : await uploadImage(file);
    isWorking = false;
  };

  const uploadSvg = async (file) => {
    const raw = await file.text();
    const { svg } = await createUpload({ svg: raw });
    svg ? update(svg) : error("is invalid");
  };

  const uploadImage = async (file) => {
    const { id, url } = await createUpload();

    const body = new FormData();
    body.append("file", file);

    const response = await fetch(url, { method: "POST", body });
    response.ok ? update(id) : error("failed to upload");
  };

  const createUpload = async (body = {}) => {
    body[csrfParam] = csrfToken;

    const response = await fetch("/image_uploads", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  };

  const ondragover = (e) => {
    e.preventDefault();
  };

  const ondragenter = (e) => {
    e.preventDefault();
    isDropping = true;
  };

  const ondragleave = (e) => {
    e.preventDefault();
    isDropping = false;
  };

  const ondrop = (e) => {
    e.preventDefault();
    upload(e.dataTransfer.files[0]);
  };

  $effect(() => {
    cfUrlRoot = getMeta("cloudflare-image-url-root");
    csrfParam = getMeta("csrf-param");
    csrfToken = getMeta("csrf-token");
  });
</script>

<Field {form} {name} {hint} {ondragover} {ondragenter} {ondragleave} {ondrop} {...mx({ containerProps: { class: "p-1" }, labelProps: { class: "pl-3" } }, fieldProps)}>
  <input onchange={(e) => upload(e.target.files[0])} bind:this={fileInputRef} {accept} type="file" hidden {...props} />

  {#if value && !isWorking && !isDropping}
    <button onclick={() => reset()} type="button" class="group/delete relative block size-10 overflow-hidden rounded bg-st-300">
      <span class="absolute left-0 top-0 inline-flex size-full items-center justify-center bg-st-950 text-st-50 opacity-0 transition-opacity group-hover/delete:opacity-100">
        <Icon type="trash" class="size-4" />
      </span>

      {#if isSVG}
        <SVG src={value} width={40} height={40} class="size-full" />
      {:else if url}
        <Image src={url} alt={name} width={40} height={40} />
      {/if}
    </button>
  {:else if isWorking}
    <span class="inline-flex size-10 items-center justify-center rounded bg-st-100 text-accent">
      <ProgressSpinner class="size-4" />
    </span>
  {:else if isDropping}
    <button type="button" class="size-10 rounded bg-st-100 text-accent">
      <Icon type="arrow-down" class="size-4 animate-bounce" />
    </button>
  {:else}
    <button onclick={() => fileInputRef.click()} type="button" class="size-10 rounded bg-st-100 text-accent transition-colors hover:bg-accent hover:text-white">
      <Icon type="plus" class="size-4" />
    </button>
  {/if}
</Field>
