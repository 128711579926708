<script>
  import { useForm } from "@inertiajs/svelte";
  import { DOMAIN_INSTRUCTIONS_URL } from "$lib/const";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import InfoList from "$lib/components/InfoList.svelte";
  import Time from "$lib/components/Time.svelte";

  let { domain } = $props();

  const form = useForm({});
</script>

<FormModal
  {form}
  title="Verify Domain"
  action={`/domains/${domain.id}/verify`}
  method="patch"
  class="space-y-8"
  rightActionProps={{ text: "Verify", iconProps: { type: "check" } }}
  submitOptions={{ preserveScroll: true }}
>
  <InfoHeader title={domain.name} iconProps={{ type: "check" }} />

  <InfoCopy>
    If you've followed our <a href={DOMAIN_INSTRUCTIONS_URL} target="_blank" class="text-accent transition-opacity hover:opacity-50">setup instructions</a>, you can verify your
    domain settings here.
  </InfoCopy>

  <InfoList
    items={[
      { label: "Last updated", value: updatedAt },
      { label: "Last verified", value: verifiedAt },
    ]}
  />
</FormModal>

{#snippet updatedAt()}
  <Time datetime={domain.updated_at} format="timeago" />
{/snippet}

{#snippet verifiedAt()}
  {#if domain.scd_verified_at}
    <Time datetime={domain.scd_verified_at} format="timeago" />
  {:else}
    never
  {/if}
{/snippet}
