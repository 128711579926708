<script>
  import { mx, parameterize } from "$lib/utils";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { title, actions = [], headerProps, children, ...props } = $props();
</script>

<div {...mx({ id: parameterize(title), class: "bg-st-200 rounded-md p-4 pt-3 space-y-6 lg:px-6 lg:pt-[18px] lg:pb-[26px]" }, props)}>
  <header {...mx({ class: "flex justify-between items-center" }, headerProps)}>
    <h2 class="text-xl">{title}</h2>

    {#if actions.length}
      <div class="flex gap-4">
        {#each actions as action}
          <ActionLink {...action} />
        {/each}
      </div>
    {/if}
  </header>

  {@render children()}
</div>
