export const WWW_URL = "https://www.supertape.com";
export const PRICING_URL = "https://www.supertape.com/pricing";
export const HELP_URL = "https://help.supertape.com";
export const REFERRAL_PROGRAM_URL = "https://help.supertape.com/docs/referral-program";
export const DOMAIN_INSTRUCTIONS_URL = "https://help.supertape.com/docs/domains#connecting-a-custom-domain";

export const VIEWS = import.meta.glob("../../views/**/+*.svelte", { eager: true });
export const FIELDS = import.meta.glob("../../views/lib/components/form/fields/*.svelte", { eager: true });

export const GLOBAL_LINKS = [
  { text: "User profile", href: "/user", target: "_modal", iconProps: { type: "user", class: "size-3.5" } },
  { text: "Switch site", href: "/sites", target: "_modal", iconProps: { type: "arrow-right-arrow-left" } },
  { text: "Help docs", href: "https://help.supertape.com", target: "_blank", iconProps: { type: "question" } },
  { text: "What's new", href: "https://www.supertape.com/blog/categories/updates", target: "_blank", iconProps: { type: "sparkles" } },
  { text: "Sign out", href: "/sessions", target: "_modal", iconProps: { type: "arrow-right-from-line" } },
];

export const SECTION_LINKS = [
  { text: "Dashboard", href: "/", iconProps: { type: "wave-pulse" } },
  { text: "Audience", href: "/audience", iconProps: { type: "hand-horns" } },
  { text: "Posts", href: "/posts", iconProps: { type: "message", class: "size-3.5" } },
  { text: "Share", href: "/share", iconProps: { type: "arrow-turn-right" } },
  { text: "Music", href: "/music", iconProps: { type: "album-collection" } },
  { text: "Merch", href: "/merch", iconProps: { type: "tags" } },
  { text: "Videos", href: "/videos", iconProps: { type: "films" } },
  { text: "Tour", href: "/tour", iconProps: { type: "tickets" } },
  { text: "Platforms", href: "/platforms", iconProps: { type: "layer-group" } },
  { text: "Design", href: "/design", iconProps: { type: "pen-ruler" }, fullWidth: true },
  { text: "Settings", href: "/settings", iconProps: { type: "sliders-up" } },
];

export const REFERRAL_LINK = { text: "Refer a friend", href: "/referrals", target: "_modal", iconProps: { type: "badge-dollar" } };

export const HARD_LIMIT_ACTION = {
  text: "Limited",
  href: "https://help.supertape.com/legal/acceptable-use-policy#limits",
  target: "_blank",
  iconProps: { type: "circle-exclamation" },
  class: "text-error",
};
