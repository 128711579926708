<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import StripeForm from "$lib/components/form/StripeForm.svelte";

  let { name, email, cents, stripe_publishable_key, stripe_client_secret } = $props();
  let formRef = $state();
</script>

<svelte:head>
  <title>New Site Billing - Supertape</title>
</svelte:head>

<Page backHref="/sites/new/plans" onButtonClick={() => formRef.submit()}>
  <Header title="Billing" description="How would you like to pay for your site? You can always change this later." />

  <StripeForm
    bind:this={formRef}
    action="/sites/new/summary"
    method="post"
    publishableKey={stripe_publishable_key}
    clientSecret={stripe_client_secret}
    defaultValues={{ name, email }}
    amountCents={cents}
    paymentEnabled
    addressEnabled
    class="rounded-md bg-st-50 p-6 sm:p-8 lg:p-10 lg:pt-3 [&>fieldset#payment-method>legend]:!border-none"
  />
</Page>
