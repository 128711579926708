export default function clickOutside(node, handler) {
  const onClick = ({ target }) => {
    if (!node.contains(target)) handler?.(target);
  };

  window.addEventListener("click", onClick);

  return {
    destroy() {
      window.removeEventListener("click", onClick);
    },
  };
}
