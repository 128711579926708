<script>
  import "vanilla-colorful";
  import "vanilla-colorful/hex-input.js";
  import Field from "../Field.svelte";
  import clickOutside from "$lib/actions/clickOutside";

  let { form, name, fieldProps } = $props();
  let color = $derived($form[name]);
  let isOpen = $state(false);
  let timeout = $state();
  let ref = $state();

  const onChange = ({ detail: { value } }) => {
    if (!value.match(/^#[0-9a-f]{6}$/i)) return;

    $form[name] = value;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      ref.closest("form").dispatchEvent(new Event("change"));
    }, 300);
  };
</script>

<Field {form} {name} {...fieldProps}>
  <div bind:this={ref} use:clickOutside={() => (isOpen = false)} class="relative flex items-center justify-end gap-2">
    <hex-input {color} oncolor-changed={onChange} prefixed>
      <input
        onfocus={() => (isOpen = true)}
        type="text"
        class="w-[70px] appearance-none bg-transparent text-right font-mono uppercase placeholder:text-st-500 autofill:shadow-[inset_0_0_0px_1000px_rgba(var(--colors-st-200))] focus:outline-none focus:ring-0 disabled:text-st-400"
        autocomplete="off"
      />
    </hex-input>

    <button type="button" onclick={() => (isOpen = true)} style:background={color} class="size-4 flex-none cursor-default rounded-full transition-colors" aria-label="Swatch"
    ></button>

    {#if isOpen}
      <div class="absolute right-0 top-6 z-10">
        <hex-color-picker {color} oncolor-changed={onChange}></hex-color-picker>
      </div>
    {/if}
  </div>
</Field>
