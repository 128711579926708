<script>
  import InfoAlert from "$lib/components/InfoAlert.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";
  import Links from "$lib/components/detail/Links.svelte";

  let { video } = $props();
</script>

<div class="space-y-2.5">
  {#if video.hidden}
    <InfoAlert text="This video is hidden" iconProps={{ type: "eye-slash" }} />
  {/if}

  <Meta name={video.name} imageUrl={video.image_url} meta={video.meta} info={video.info} infoPlaceholder="Add a description, credits, accolades, etc." isVideo />

  <Links links={video.links} />
</div>
