<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  let { tool_link, tool_settings } = $props();

  const form = useForm(tool_link);

  const transformData = ({ id, tool_id, ...settings }) => {
    return { tool_link: { tool_id, settings } };
  };

  let title = $derived(tool_link.id ? "Edit Tool" : "New Tool");
  let method = $derived(tool_link.id ? "put" : "post");
  let action = $derived(tool_link.id ? `/tools/${tool_link.id}` : "/tools");
  let leftAction = $derived(tool_link.id ? "Close" : "Back");
</script>

<FormModal {form} {title} {method} {action} {leftAction} {transformData}>
  {#each tool_settings as { id, name, description, mask, placeholder, pattern }}
    <InputField {form} name={id} {placeholder} {pattern} maskProps={mask} fieldProps={{ label: name, hint: description }} required />
  {/each}

  <InfoCopy class="mt-4">
    Enabling this will add a <a href="https://help.supertape.com/docs/analytics#cookie-consent" target="_blank">cookie consent banner</a> to your site in required locations.
  </InfoCopy>
</FormModal>
