<script>
  import { mx } from "$lib/utils";
  import Field from "../Field.svelte";
  import Icon from "../../Icon.svelte";

  let { form, name, options, fieldProps, ...props } = $props();

  let propsMx = $derived(
    mx(
      {
        id: name,
        name,
        class:
          "!gradient-mask-none w-full flex-1 appearance-none bg-transparent pr-4 text-right placeholder:text-st-500 autofill:shadow-[inset_0_0_0px_1000px_rgba(var(--colors-st-200))] focus:outline-none focus:ring-0 disabled:text-st-400",
      },
      props,
    ),
  );
</script>

<Field {form} {name} {...fieldProps}>
  <div class="relative">
    <select bind:value={$form[name]} style:direction="rtl" {...propsMx}>
      {#each options as { label, value, disabled }}
        <option {value} disabled={disabled || value === "---"}>{label}</option>
      {/each}
    </select>

    <Icon type="angles-up-down" class="pointer-events-none absolute right-0 top-1.5 size-3" />
  </div>
</Field>
