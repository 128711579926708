<script>
  import { mx } from "$lib/utils";
  import FramedIcon from "./FramedIcon.svelte";

  const SIZES = {
    base: {
      props: { class: "gap-4" },
      titleProps: { class: "text-2xl" },
    },
    lg: {
      props: { class: "gap-4" },
      titleProps: { class: "text-3xl font-poppins-medium tracking-tight" },
    },
    xl: {
      props: { class: "gap-8" },
      titleProps: { class: "text-[40px] leading-[46px] sm:text-5xl mb-2 font-poppins-medium tracking-tighter text-balance" },
    },
  };

  let { title, titleProps, framedIconProps, iconProps, size = "base", ...props } = $props();
  let sz = $derived(SIZES[size]);
</script>

<div {...mx({ class: "flex flex-col items-center justify-center" }, sz.props, props)}>
  <FramedIcon iconProps={mx({ class: "size-6" }, iconProps)} {...mx({ class: "size-20" }, framedIconProps)} />
  <h2 {...mx({ class: "text-center tracking-tight" }, sz.titleProps, titleProps)}>{@html title}</h2>
</div>
