<script>
  import { mx } from "$lib/utils";

  let { info, placeholder = "No info", ...props } = $props();
</script>

<div {...mx({ class: "max-h-40 text-xs font-mono overflow-y-auto" }, props)}>
  {#if info}
    <article class="rich-text text-sm [&_h1]:text-sm [&_h2]:text-sm [&_h3]:text-sm [&_h4]:text-sm [&_h5]:text-sm">{@html info}</article>
  {:else}
    <p class="text-sm text-st-500">{placeholder}</p>
  {/if}
</div>
