<script>
  import { toast } from "svelte-sonner";

  let { url, isPosts = false, ...props } = $props();
  let ref = $state();
  let refreshing = $state(true);

  const refresh = () => {
    if (refreshing) return;
    refreshing = true;
    ref.contentWindow.postMessage("refresh", url.split("#")[0]);
  };

  const onMessage = ({ data }) => {
    if (data === "navigation") toast.success("Link disabled in preview");
  };

  $effect(() => {
    if (refreshing) setTimeout(() => (refreshing = false), 1000);
  });
</script>

<svelte:window onmessage={onMessage} on:preview:refresh={refresh} />
<iframe bind:this={ref} src={url} title="Preview" sandbox="allow-scripts allow-same-origin" {...props}></iframe>
