<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import InfoBlock from "$lib/components/InfoBlock.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { platform, profiles } = $props();
  let title = $derived(`Connect to ${platform.name}`);
</script>

<svelte:head>
  <title>{title} - Supertape</title>
</svelte:head>

<InfoBlock {title} iconProps={{ type: platform.icon }}>
  <StackList>
    {#each profiles as profile}
      <StackListItem as="Link" href="/platforms/authenticated" method="post" data={{ site_link: profile }}>
        {#snippet image()}
          <Avatar name={profile.name} imageProps={{ src: profile.image_url }} />
        {/snippet}

        {#snippet title()}
          {profile.name}
        {/snippet}

        {#snippet action()}
          <Icon type="plus" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</InfoBlock>
