<script>
  import { mx, humanizeNumber, numberToPercentage, commaNumber } from "$lib/utils";
  import Icon from "./Icon.svelte";

  let { delta, isPercentage = false, ...props } = $props();
  let isInfinity = $derived(Number.isNaN(delta) || !Number.isFinite(delta));
</script>

{#snippet content()}
  {#if isPercentage}
    <span>{numberToPercentage(Math.abs(delta))}</span>
  {:else}
    <span title={commaNumber(delta)}>{humanizeNumber(Math.abs(delta))}</span>
  {/if}
{/snippet}

<div {...mx({ class: "flex items-center gap-1 font-mono" }, props)}>
  {#if isInfinity}
    <Icon type="infinity" class="size-5" />
  {:else if delta < 0}
    <Icon type="arrow-down" class="size-3.5 text-st-500" />
  {:else if delta > 0}
    <Icon type="arrow-up" class="size-3.5 text-green-500" />
  {/if}

  {#if !isInfinity}
    {@render content()}
  {/if}
</div>
