<script>
  import Modal from "$lib/components/Modal.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import SVG from "$lib/components/SVG.svelte";
  import wordmarkSvg from "$assets/images/wordmark.svg?raw";
  import nameSvg from "$assets/images/settings/name.svg?raw";

  const TLDS = [
    { tld: "band", regularPrice: "$37.99", discountPrice: "$24.99" },
    { tld: "live", regularPrice: "$39.99", discountPrice: "$3.99" },
    { tld: "info", regularPrice: "$26.99", discountPrice: "$4.99" },
  ];
</script>

<Modal title="New Domain" leftAction="Back">
  <div class="space-y-4">
    <div class="flex items-center justify-center gap-2 text-2xl">
      <SVG src={wordmarkSvg} class="h-8" />
      <Icon type="xmark" class="size-4 text-st-500" />
      <SVG src={nameSvg} class="-mt-2.5 h-7" />
    </div>

    <InfoCopy class="px-8">We've teamed up with Name.com so you can find a domain, get a discount, and have it automatically setup with your site.</InfoCopy>

    <div class="grain flex flex-col justify-center gap-6 rounded-md bg-st-200 p-6 sm:gap-8 sm:p-8">
      <p class="text-center font-mono text-sm uppercase text-st-500">Get discounts on select domains</p>

      <div class="space-y-1">
        {#each TLDS as { tld, regularPrice, discountPrice }}
          <div class="flex items-center gap-2 rounded-full bg-st-50 px-4 py-3">
            <Icon type="globe" class="size-4 flex-none text-st-400" />
            <div class="grow"><span class="grow text-st-500">artist</span><span class="font-poppins-medium">.{tld}</span></div>
            <div class="flex-none text-xs text-st-500 line-through">{regularPrice}</div>
            <div class="flex-none text-[#6FDA78]">{discountPrice}</div>
          </div>
        {/each}
      </div>
    </div>

    <Link
      href="/domains/new/purchase/search"
      target="_modal"
      class="font-poppins-medium block flex w-full items-center justify-center gap-1.5 rounded-md bg-accent px-4 py-2.5 text-center text-xl text-white transition-opacity hover:opacity-50 active:opacity-50"
    >
      <span>Find a domain</span>
      <Icon type="arrow-right" class="size-[18px]" />
    </Link>
  </div>
</Modal>
