<script>
  import { mx } from "$lib/utils";
  import InfoHeader from "./InfoHeader.svelte";

  let { children, ...props } = $props();
</script>

<div class="w-full max-w-md space-y-8">
  <InfoHeader {...mx({ size: "lg" }, props)} />
  {@render children()}
</div>
