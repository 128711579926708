<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";

  let { action, event_link, event_options } = $props();

  const form = useForm(event_link);
</script>

<FormModal {form} title="Edit Link" {action} method="patch">
  <SelectField {form} name="event_id" options={event_options} />
</FormModal>
