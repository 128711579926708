<script>
  import { RadioGroup } from "bits-ui";
  import { cn, mx, parameterize } from "$lib/utils";
  import Field from "../Field.svelte";

  let { form, name, items, child, ref = $bindable(null), fieldProps, ...props } = $props();
  let propsMx = $derived(mx({ class: "space-y-1" }, props));

  const onValueChange = (value) => {
    ref.closest("form").dispatchEvent(new Event("change", { detail: { value } }));
  };
</script>

<Field {form} {name} {...fieldProps} noContainer>
  <RadioGroup.Root bind:ref bind:value={$form[name]} {onValueChange} {...propsMx}>
    {#each items as item}
      <RadioGroup.Item id={parameterize(item.value)} value={item.value} disabled={item.disabled} class="block w-full outline-none">
        {#snippet children({ checked })}
          {#if child}
            {@render child({ checked, ...item })}
          {:else}
            <div
              class={cn(
                "flex w-full items-center justify-between rounded-md bg-st-200 px-4 py-3 transition-colors hover:bg-st-300",
                checked && "bg-accent text-white hover:bg-accent",
              )}
            >
              {item.label}
            </div>
          {/if}
        {/snippet}
      </RadioGroup.Item>
    {/each}
  </RadioGroup.Root>
</Field>
