<script>
  import { page } from "@inertiajs/svelte";
  import { cn } from "$lib/utils";
  import Link from "$lib/components/Link.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { billing } = $derived($page.props);
</script>

{#if billing}
  <Link
    href={billing.href}
    target="_modal"
    class="grain group grid grid-cols-2 rounded-md bg-st-200 px-2.5 pb-10 pt-1.5 font-mono uppercase tracking-wide transition-all hover:bg-accent hover:text-st-50 active:opacity-70 lg:flex lg:flex-col lg:px-2 lg:py-1.5 lg:text-sm dark:hover:!text-st-950"
  >
    <div class="flex basis-1/3">
      <div class={cn("font-poppins-medium text-base normal-case tracking-tight group-hover:!text-st-50 dark:group-hover:!text-st-950", billing.warning && "text-error")}>
        {billing.title}
      </div>

      <Icon type="circle-small" dir="solid" class={cn("mt-[3px] size-2.5 group-hover:text-white", billing.warning && "text-error")} />
    </div>

    <p>{billing.description}</p>

    <div class="col-start-2 flex items-center gap-1 opacity-50 group-hover:text-st-50 dark:group-hover:!text-st-950">
      <span>{billing.cta}</span>
      <Icon type="arrow-right" class="size-3.5" />
    </div>
  </Link>
{/if}
