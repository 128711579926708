<script>
  import { Image } from "@unpic/svelte";
  import { cn, mx, initials } from "$lib/utils";

  let { name, imageProps, ...props } = $props();
  let hasImage = $derived(!!imageProps?.src);
  let nameInitials = $derived(initials(name));
</script>

<div
  {...mx({ class: cn("rounded-full size-12 overflow-hidden", !hasImage && "inline-flex items-center justify-center bg-st-950 text-st-300 text-center leading-none px-1") }, props)}
>
  {#if hasImage}
    <Image {...mx({ alt: name, width: 48, height: 48 }, imageProps)} />
  {:else}
    {nameInitials}
  {/if}
</div>
