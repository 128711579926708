<script>
  import { humanizeUrl } from "$lib/utils";
  import Table from "./Table.svelte";
  import TableRow from "./TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { links } = $props();

  let getActions = (link) => {
    return [
      { text: "Share", href: link.share_href, target: "_modal", iconProps: { type: "arrow-turn-right" } },
      { text: "Open", href: link.source_url, target: "_blank", iconProps: { type: "arrow-up-right" } },
      { text: "Edit", href: link.edit_href, target: "_modal", iconProps: { type: "pen" } },
    ];
  };
</script>

<Table title="Platforms">
  {#each links as link}
    <TableRow actions={getActions(link)}>
      {#snippet colOne()}
        <div class="flex items-center gap-7">
          <Icon type={link.icon} class="size-5 flex-none" />
          <span class="font-sans text-base">{link.name}</span>
        </div>
      {/snippet}

      {#snippet colTwo()}
        <Link href={link.source_url} class="text-sm transition-colors hover:text-accent">
          {humanizeUrl(link.source_url)}
        </Link>
      {/snippet}
    </TableRow>
  {/each}
</Table>
