import { mergeProps } from "svelte-toolbelt";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import humanizeString from "humanize-string";
import humanNumber from "human-number";
export * from "@ember/string";

export const cn = (...args) => {
  return twMerge(clsx(args));
};

export const mx = (...args) => {
  const obj = mergeProps(...args);
  if (obj.class) obj.class = twMerge(obj.class);
  return obj;
};

export const humanize = (str) => humanizeString(str.replace(/_id$/, ""));

export const humanizeUrl = (str) => str.replace(/^https?:\/\//, "").replace(/^www\./, "");

export const humanizeNumber = (num) =>
  humanNumber(num, (n) => {
    const parsed = Number.parseFloat(n);
    return Number.isInteger(parsed) ? parsed.toString() : parsed.toFixed(1);
  });

export const numberToPercentage = (num) => `${Math.round((num / 100) * 100)}%`;

export const commaNumber = (num) => new Intl.NumberFormat().format(num);

export const parameterize = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, "-");

export const initials = (str) =>
  str
    .split(/(\s+|-)/)
    .map((s) => s[0])
    .join("");

export const getMeta = (name) => document.querySelector(`meta[name=${name}]`)?.content;
