<script>
  import Icon from "$lib/components/Icon.svelte";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { event } = $props();
</script>

<Table title="Venue">
  <TableRow actions={event.in_person ? [{ text: "View on map", href: event.venue_map_url, iconProps: { type: "map-pin" } }] : []}>
    {#snippet colOne()}
      <div class="flex items-center gap-8">
        <Icon type={event.in_person ? "amp-guitar" : "desktop"} class="size-4 flex-none" />
        <span class="font-sans text-base">{event.venue_name}</span>
      </div>
    {/snippet}

    {#snippet colTwo()}
      {#if event.in_person}
        {[event.venue_city, event.venue_region].filter(Boolean).join(", ")}
      {/if}
    {/snippet}

    {#snippet colThree()}
      {#if event.in_person}
        <div class="flex items-center gap-2">
          <Badge size="sm">{event.venue_country.id}</Badge>
          <span class="text-sm uppercase">{event.venue_country.name}</span>
        </div>
      {/if}
    {/snippet}
  </TableRow>
</Table>
