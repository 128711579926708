<script>
  import { maska } from "maska/svelte";
  import { mx } from "$lib/utils";
  import Field from "../Field.svelte";

  let { form, name, type = "text", autocomplete, placeholder, maskProps, fieldProps, ...props } = $props();

  if (type === "code") {
    type = "text";
    autocomplete = "one-time-code";
    placeholder ||= "XXXXXX";
    maskProps ||= { mask: "######" };
  }

  if (type === "slug") {
    type = "text";
    fieldProps ||= { label: "URL slug" };
    placeholder ||= "your-slug";
    maskProps ||= {
      mask: "X",
      tokens: {
        X: {
          pattern: /[a-z0-9-]/,
          repeated: true,
          transform: (char) => char.toLowerCase(),
        },
      },
    };
  }

  if (type === "upc") {
    type = "text";
    fieldProps ||= { label: "UPC" };
    placeholder ||= "X-XXXXX-XXXXX-X";
    maskProps ||= { mask: "#-#####-#####-#" };
  }

  if (type === "isrc") {
    type = "text";
    fieldProps ||= { label: "ISRC" };
    placeholder ||= "XX-XXX-XX-XXXXX";
    maskProps ||= { mask: "@@-***-##-#####" };
  }

  if (type === "datetime") {
    type = "datetime-local";
  }

  let propsMx = $derived(
    mx(
      {
        id: name,
        type,
        name,
        autocomplete,
        placeholder,
        class:
          "w-full flex-1 appearance-none bg-transparent text-right placeholder:text-st-500 autofill:shadow-[inset_0_0_0px_1000px_rgba(var(--colors-st-200))] focus:outline-none focus:ring-0 disabled:text-st-400",
      },
      props,
    ),
  );
</script>

<Field {form} {name} {...fieldProps}>
  <input bind:value={$form[name]} use:maska={maskProps} {...propsMx} />
</Field>
