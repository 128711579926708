<script>
  import { router } from "@inertiajs/svelte";
  import { Image } from "@unpic/svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Menu from "$lib/components/Menu.svelte";

  let { posts } = $props();

  const onsort = async (position) => {
    router.patch("/posts/position", { position }, { only: ["posts", "flash"] });
  };
</script>

<StackList {onsort} class="h-full basis-full lg:basis-1/2 lg:snap-y lg:snap-mandatory lg:overflow-auto">
  {#each posts as post (post.id)}
    <StackListItem data-id={post.id}>
      {#snippet image()}
        <Image src={post.image_url} alt="Post" width={48} height={48} class="size-12 rounded-md" />
      {/snippet}

      {#snippet title()}
        {post.message}
      {/snippet}

      {#snippet action()}
        <Menu
          items={[
            { text: "Edit", href: `/posts/${post.id}/edit`, target: "_modal", iconProps: { type: "pen" } },
            { text: "Delete", href: `/posts/${post.id}`, method: "delete", confirm: "This will remove this post from your site.", iconProps: { type: "trash" } },
          ]}
        />
      {/snippet}
    </StackListItem>
  {/each}
</StackList>
