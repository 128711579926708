<script>
  import { cn, mx } from "$lib/utils";
  import Link from "./Link.svelte";
  import Icon from "./Icon.svelte";
  import ProgressSpinner from "./ProgressSpinner.svelte";

  let { href, text, iconProps, isWorking = false, showSpinner = false, ...props } = $props();

  let propsMx = $derived(
    mx(
      {
        class: cn(
          "inline-flex items-center gap-1.5 tracking-wide font-mono uppercase text-accent hover:opacity-50 max-lg:active:opacity-50 transition-opacity",
          (isWorking || props.disabled) && "opacity-50 pointer-events-none",
        ),
      },
      props,
    ),
  );
</script>

{#snippet content()}
  {#if isWorking && showSpinner}
    <ProgressSpinner class="size-3.5 text-current" />
  {:else}
    <Icon {...mx({ class: "size-3.5" }, iconProps)} />
  {/if}

  <span>{text}</span>
{/snippet}

{#if href}
  <Link {href} {...propsMx}>
    {@render content()}
  </Link>
{:else}
  <button {...propsMx}>
    {@render content()}
  </button>
{/if}
