<script>
  import { mx } from "$lib/utils";
  import Pod from "./Pod.svelte";

  let { children, ...props } = $props();
</script>

<Pod {...mx({ headerProps: { class: "lg:col-span-12" } }, props)}>
  <table class="w-full table-fixed">
    <tbody class="divide-y divide-st-300 border-y border-st-300">
      {@render children()}
    </tbody>
  </table>
</Pod>
