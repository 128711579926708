<script>
  import Icon from "$lib/components/Icon.svelte";
</script>

<div class="z-10 flex size-16 items-center justify-center rounded-full bg-st-950 text-st-50 shadow-xl shadow-st-950/30 dark:shadow-st-50/30">
  <Icon type="supertape" class="size-8" />
</div>

<svg class="-z-1 absolute size-[200px] overflow-visible" viewBox="0 0 200 200">
  {#each Array(3) as _, i}
    <circle cx="100" cy="100" r="10%" class="stroke-width-[2px] animate-sonar fill-none stroke-st-950/30" style={`animation-delay: ${(i + 1) * 0.35}s`} />
  {/each}
</svg>

<style>
  @keyframes sonar {
    0% {
      r: 10%;
      stroke-opacity: 0.5;
    }
    100% {
      r: 200%;
      stroke-opacity: 0;
    }
  }

  .animate-sonar {
    animation: sonar 3.5s infinite ease-in-out;
  }
</style>
