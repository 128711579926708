<script>
  import { page, router } from "@inertiajs/svelte";
  import { GLOBAL_LINKS, SECTION_LINKS, REFERRAL_LINK } from "$lib/const";
  import { cn, mx } from "$lib/utils";
  import Link from "$lib/components/Link.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import Actions from "./Actions.svelte";
  import NavLinks from "./NavLinks.svelte";
  import ActionButton from "$lib/components/ActionButton.svelte";
  import BillingStatus from "./BillingStatus.svelte";

  let { section } = $props();
  let mobileOpen = $state(false);

  router.on("navigate", () => (mobileOpen = false));
</script>

<div class={cn("fixed top-0 w-full overflow-y-auto bg-st-50 bg-opacity-50 backdrop-blur-xl", mobileOpen && "h-[100dvh]")}>
  <header class={cn("sticky top-0 flex w-full items-center justify-between gap-2 p-4", mobileOpen && "bg-st-50 bg-opacity-50 backdrop-blur-xl")}>
    <button onclick={() => (mobileOpen = !mobileOpen)} class="flex w-[64px] flex-none items-center gap-2 transition-opacity active:opacity-70">
      <Avatar name={$page.props.current.site.name} class="size-10 flex-none" imageProps={{ src: $page.props.current.site.image_url, width: 40, height: 40 }} />
      <Icon type="chevron-down" class="size-4 flex-none" />
    </button>

    {#if mobileOpen}
      <div class="font-poppins-medium flex-1 truncate text-center">{$page.props.current.site.name}</div>

      <button onclick={() => (mobileOpen = false)} class="w-[64px] flex-none text-right">
        <FramedIcon iconProps={{ type: "xmark" }} class="size-10 bg-st-200 text-st-950 transition-colors max-lg:active:bg-st-300 dark:bg-st-200" />
      </button>
    {:else if section}
      <Link href={section.href} class="flex flex-1 items-center justify-center gap-2 text-st-500 transition-colors hover:text-accent">
        {#key section.iconProps.type}
          <Icon {...mx({ class: "size-4" }, section.iconProps)} />
        {/key}
        <h1 class="truncate font-mono uppercase">{section.text}</h1>
      </Link>

      <div class="w-[64px] flex-none text-right">
        <Actions />
      </div>
    {/if}
  </header>

  {#if mobileOpen}
    <div class="flex flex-1 flex-col gap-8 p-4">
      <div class="flex-none space-y-2">
        {#if $page.props.current.user.owner}
          <BillingStatus />
        {/if}

        <ActionButton
          text="View site"
          href={$page.props.current.site.url}
          target="_blank"
          class="w-full justify-between rounded-md border-accent px-2.5 py-2 tracking-wide text-accent"
          iconProps={{ type: "arrow-up-right", class: "size-[18px]" }}
        />
      </div>

      <nav class="flex-1 space-y-8">
        <NavLinks title="Promote" links={SECTION_LINKS.slice(0, 4)} active={section} />
        <NavLinks title="Inventory" links={SECTION_LINKS.slice(4, 8)} active={section} />
        <NavLinks title="Customize" links={SECTION_LINKS.slice(8, 11)} active={section} />
        <NavLinks title="Personal" links={[REFERRAL_LINK, ...GLOBAL_LINKS]} active={section} />
      </nav>
    </div>
  {/if}
</div>
