<script>
  import { Image } from "@unpic/svelte";
  import Iso from "$layouts/Iso.svelte";
  import reelUrl from "$assets/images/onboarding/reel.jpg";

  let props = $props();

  const actionsProps = {
    framedIconProps: {
      class: "size-8 lg:mt-1 transition-colors bg-st-50/20 hover:bg-st-50/30 text-white",
      iconProps: { type: "ellipsis", class: "size-6" },
    },
  };
</script>

<Iso headerProps={{ class: "text-white" }} {actionsProps} {...props} />
<Image src={reelUrl} alt="Reel" layout="fullWidth" class="absolute inset-0 size-full" priority />
