<script>
  import { onMount } from "svelte";
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import RadioGroupField from "$lib/components/form/fields/RadioGroupField.svelte";

  const items = [
    { label: "Last 7 days", value: "last_7_days" },
    { label: "Last 30 days", value: "last_30_days" },
    { label: "Last 90 days", value: "last_90_days" },
    { label: "Last 365 days", value: "last_365_days" },
    { label: "All time", value: "all" },
  ];

  let { date_range, action } = $props();
  let form = useForm({ date_range });

  onMount(() => {
    let newParams = new URLSearchParams(location.search);
    newParams.delete("date_range");

    let newAction = new URL(action, location.origin);
    newAction.search = newParams.toString();

    action = newAction.toString();
  });
</script>

<FormModal {form} {action} title="Dates" rightActionProps={{ text: "Done" }}>
  <RadioGroupField {form} name="date_range" {items} />
</FormModal>
