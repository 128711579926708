<script>
  import { humanize } from "$lib/utils";
  import Modal from "$lib/components/Modal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoList from "$lib/components/InfoList.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { user } = $props();

  let items = $derived([
    { label: "Email", value: user.email },
    { label: "Appearance", value: humanize(user.appearance) },
  ]);
</script>

<Modal title="User Profile">
  {#snippet rightAction()}
    <ActionLink text="Edit" href="/user/edit" target="_modal" iconProps={{ type: "pen" }} />
  {/snippet}

  <div class="space-y-8">
    <InfoHeader title={user.name} iconProps={{ type: "user" }} />
    <InfoList {items} />
  </div>
</Modal>
