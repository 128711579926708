<script>
  import { useForm } from "@inertiajs/svelte";
  import { cn } from "$lib/utils";
  import Modal from "$lib/components/Modal.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import ShareAction from "$lib/components/ShareAction.svelte";
  import Form from "$lib/components/Form.svelte";
  import SwitchField from "$lib/components/form/fields/SwitchField.svelte";
  import UnlockField from "$lib/components/form/fields/UnlockField.svelte";
  import SVG from "$lib/components/SVG.svelte";
  import badgeSVG from "$assets/images/referrals/referral_badge.svg?raw";

  let { referral_program_url, referral_url, referral_amount, badge_enabled, show_badge, update_badge_path, meta } = $props();

  const form = useForm({
    show_badge,
  });
</script>

<Modal title="Referral Program">
  {#snippet rightAction()}
    <ActionLink text="Info" href={referral_program_url} iconProps={{ type: "circle-info" }} />
  {/snippet}

  <div class="space-y-8">
    <InfoHeader
      title={`Refer a friend and<br>you both get ${referral_amount}`}
      iconProps={{ dir: "solid", type: "badge-dollar" }}
      framedIconProps={{ class: "bg-[#46D141]" }}
      size="lg"
    />

    <InfoCopy>
      Each time someone uses your referral link to sign up for our Pro plan you'll both get credited {referral_amount} on your next invoice.
    </InfoCopy>

    <div class="space-y-2">
      <ShareAction label="Share your referral link" url={referral_url} />

      <div class="grid grid-cols-2 rounded-md bg-st-200">
        {#each meta as { label, value }, index}
          <div class={cn("flex flex-col justify-between gap-1 border-st-300 px-4 py-3", index % 2 === 0 && "border-r", index < 2 && "border-b")}>
            <div class="font-poppins-medium text-xs tracking-wide text-st-500">{label}</div>
            <div class="truncate font-mono text-sm uppercase lg:text-base">{value}</div>
          </div>
        {/each}
      </div>

      <Form {form} action="/referrals" method="patch" target="_modal" class="space-y-0 rounded-md bg-st-200" autoSubmit>
        {#if badge_enabled}
          <SwitchField
            {form}
            name="show_badge"
            fieldProps={{
              label: "Show referral badge on my site",
              labelProps: { class: "group-has-[:focus]:text-st-950/60" },
              containerProps: { class: "rounded-b-none px-4 py-3.5 group-has-[:focus]:ring-0" },
            }}
          />
        {:else}
          <UnlockField {form} name="show_badge" fieldProps={{ label: "Upgrade to hide referral badge", containerProps: { class: "rounded-b-none px-4 py-3.5" } }} />
        {/if}

        <div class="flex justify-center border-t border-st-300 px-6 pb-6">
          <SVG src={badgeSVG} width={238} height={100} />
        </div>
      </Form>
    </div>
  </div>
</Modal>
