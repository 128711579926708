<script>
  import Modal from "$lib/components/Modal.svelte";
  import Badge from "$lib/components/Badge.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  let { invoices } = $props();
</script>

<Modal title="Invoice History">
  {#if invoices.length}
    <div class="divide-y divide-st-200 font-mono text-sm">
      {#each invoices as { date, amount, href }}
        <div class="flex gap-2 py-3">
          <div class="flex-1 whitespace-nowrap">{date}</div>

          <div class="w-20 whitespace-nowrap">{amount}</div>

          <div class="w-20 whitespace-nowrap">
            <Badge>PAID</Badge>
          </div>

          <div class="w-10 flex-none">
            <ActionLink {href} target="_blank" text="PDF" iconProps={{ type: "arrow-down-to-line" }} />
          </div>
        </div>
      {/each}
    </div>
  {:else}
    <InfoCopy>You don't have any invoices yet.</InfoCopy>
  {/if}
</Modal>
