<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";

  let { name, album_name, presets } = $props();
</script>

<svelte:head>
  <title>New Site Styles - Supertape</title>
</svelte:head>

<Page backHref="/sites/new/templates">
  <Header title="Select a style" description="Start with a preset and customize your fonts, colors, logo, and more later." />

  <StackList class="mb-6 w-full space-y-2.5 sm:mb-14">
    {#each presets as preset}
      <StackListItem
        as="Link"
        href="/sites/new/plans"
        method="post"
        data={{ preset: preset.id }}
        style={{ background: preset.colors.background, color: preset.colors.foreground }}
        class="block w-full transition-opacity hover:opacity-50 active:opacity-50 [&>div]:sm:p-6 [&>div]:sm:py-5"
      >
        {#snippet title()}
          <span class={`text-2xl sm:text-3xl font-${preset.fonts.header}`} style={`color: ${preset.colors.foreground}`}>{name}</span>
        {/snippet}

        {#snippet subtitle()}
          <span class={`"text-sm sm:text-base font-${preset.fonts.body}`} style={`color: ${preset.colors.foreground}`}>{album_name}</span>
        {/snippet}

        {#snippet action()}
          <Icon type="arrow-right" class="size-5 sm:size-6" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Page>
