<script>
  import { mx } from "$lib/utils";
  import Icon from "./Icon.svelte";

  let { text, iconProps, ...props } = $props();

  props = mx({ class: "flex flex-col sm:flex-row items-center justify-center gap-4 text-center sm:text-left font-mono uppercase" }, props);
  iconProps = mx({ class: "flex-none size-4" }, iconProps);
</script>

<div {...props}>
  <Icon {...iconProps} />
  <span>{text}</span>
</div>
