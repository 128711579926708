<script>
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { tracks } = $props();
</script>

<Table title="Tracks">
  {#each tracks as track}
    <TableRow actions={[{ text: "Edit", href: track.edit_href, target: "_modal", iconProps: { type: "pen" } }]}>
      {#snippet colOne()}
        <div class="flex items-center gap-8">
          <span class="w-4 text-st-500">{track.number}</span>
          <span class="font-sans text-base">{track.name}</span>
        </div>
      {/snippet}

      {#snippet colTwo()}
        {track.duration}
      {/snippet}

      {#snippet colThree()}
        <div class="flex items-center gap-2">
          <Badge size="sm">ISRC</Badge>
          <span class="text-sm uppercase">{track.isrc || "Unknown"}</span>
        </div>
      {/snippet}
    </TableRow>
  {/each}
</Table>
