<script>
  import { cn, mx } from "$lib/utils";
  import Icon from "$lib/components/Icon.svelte";

  let { onclick, isActive = false, iconProps } = $props();
</script>

<button type="button" {onclick} class={cn("size-9 rounded-lg text-st-400 transition-colors hover:bg-st-200 hover:text-accent", isActive && "text-accent")}>
  <Icon {...mx({ class: "size-4" }, iconProps)} />
</button>
