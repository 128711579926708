<script>
  import ShareSite from "./ShareSite.svelte";
  import ShareResoruce from "./ShareResource.svelte";

  let { site, resources } = $props();
</script>

<ShareSite {...site} />

{#if resources.length}
  <h2 class="mb-3 mt-6 text-xl tracking-tight">Share your latest releases</h2>

  <section class="grid grid-cols-1 gap-2.5 lg:grid-cols-2">
    {#each resources as resource}
      <ShareResoruce {...resource} />
    {/each}
  </section>
{/if}
