<script>
  import { PRICING_URL } from "$lib/const";
  import Modal from "$lib/components/Modal.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import PlanPicker from "$lib/components/PlanPicker.svelte";

  let props = $props();
</script>

<Modal title="Plan" class="sm:max-w-[670px]">
  {#snippet rightAction()}
    <ActionLink text="Compare" href={PRICING_URL} iconProps={{ type: "arrow-up-right" }} />
  {/snippet}

  <PlanPicker {...props} />
</Modal>
