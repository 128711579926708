<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { useForm } from "@inertiajs/svelte";
  import IsoForm from "$lib/components/form/IsoForm.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import Link from "$lib/components/Link.svelte";

  const form = useForm({
    name: null,
    email: null,
  });
</script>

<svelte:head>
  <title>Sign Up - Supertape</title>
  <meta name="description" content="Sign up for a Supertape band website to manage your music, merch, tour, videos, design, and more." />
</svelte:head>

<IsoForm {form} method="post" action="/signup" button="Sign Up">
  <InputField {form} name="name" type="text" placeholder="Your Name" required autofocus />
  <InputField {form} name="email" type="email" placeholder="your@email.com" required />

  {#snippet footer()}
    By signing up you agree to our <Link href="https://help.supertape.com/legal/terms-of-service">Terms of Service</Link>
  {/snippet}
</IsoForm>
