<script>
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { team_members, actions } = $props();
</script>

<Table title="Team" {actions}>
  {#each team_members as { name, icon, role, email, actions }}
    <TableRow {actions}>
      {#snippet colOne()}
        <Icon type={icon} class="size-4 flex-none" />
        <span class="font-sans text-base">{name}</span>
      {/snippet}

      {#snippet colTwo()}
        <Badge>{role}</Badge>
      {/snippet}

      {#snippet colThree()}
        <a href="mailto:{email}" class="transition-colors hover:text-accent">{email}</a>
      {/snippet}
    </TableRow>
  {/each}
</Table>
