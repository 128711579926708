<script>
  import { cn, commaNumber, humanizeNumber } from "$lib/utils";
  import Delta from "$lib/components/Delta.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";

  let { count, delta, platforms } = $props();
</script>

<div class="flex w-full flex-col justify-between gap-4 rounded-md bg-st-200 p-4 pt-[13px] lg:p-6 lg:pt-4">
  <div class="flex flex-col gap-2">
    <h2>Followers</h2>

    <div class="flex items-end justify-between">
      <div title={commaNumber(count)} class="text-5xl tracking-tight">{humanizeNumber(count)}</div>
      <Delta {delta} class={cn("rounded-md bg-st-300 px-2 py-1.5", delta !== 0 && "pl-3")} />
    </div>
  </div>

  <div class="min-w-0 snap-y snap-mandatory space-y-1 overflow-auto scrollbar-hide lg:h-[85%]">
    {#each platforms as platform}
      <div class="relative flex h-14 min-w-0 snap-start items-center justify-between gap-2 overflow-hidden rounded-md bg-st-50/50 p-2">
        <FramedIcon iconProps={{ type: platform.icon, class: "size-5 text-white" }} class="size-10" style={{ background: platform.color }} />

        <div class="min-w-0 grow truncate">
          <h3>{platform.name}</h3>
          <p title={commaNumber(platform.count)} class="text-xs text-st-500">{humanizeNumber(platform.count)}</p>
        </div>

        <Delta delta={platform.delta} class="flex-none px-2" />
      </div>
    {/each}
  </div>
</div>
