<script>
  import { useForm } from "@inertiajs/svelte";
  import { Image } from "@unpic/svelte";
  import { cn } from "$lib/utils";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { title, action, resources } = $props();
  let positions = $state(resources.map((r) => r.id.toString()));
  let visibility = $state(Object.fromEntries(resources.map((r) => [r.id, !r.hidden])));

  const form = useForm({
    updates: {},
  });

  const toggle = (id) => {
    visibility[id] = !visibility[id];
    update();
  };

  const onsort = (ids) => {
    positions = ids;
    update();
  };

  const update = () => {
    $form.updates = positions.map((id) => ({ id, visible: visibility[id] }));
  };
</script>

<FormModal {form} {action} {title} method="patch">
  <StackList {onsort}>
    {#each resources as resource}
      {@const isVisible = visibility[resource.id]}

      <StackListItem data-id={resource.id}>
        {#snippet image()}
          <div class={cn("flex size-10 items-center justify-center overflow-hidden rounded bg-st-100 shadow-lg transition-opacity", !isVisible && "opacity-50")}>
            {#if resource.image_url}
              <Image src={resource.image_url} alt={resource.name} width={40} height={40} />
            {:else}
              <Icon type="xmark" class="size-4 text-st-500" />
            {/if}
          </div>
        {/snippet}

        {#snippet title()}
          <span class={cn("transition-opacity", !isVisible && "opacity-50")}>{resource.name}</span>
        {/snippet}

        {#snippet subtitle()}
          {resource.type} &#x2022; {resource.year || `${resource.month} ${resource.day}`}
        {/snippet}

        {#snippet action()}
          <button type="button" onclick={() => toggle(resource.id)}>
            {#if isVisible}
              <Icon type="eye" class="size-4" />
            {:else}
              <Icon type="eye-slash" class="size-4" />
            {/if}
          </button>
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</FormModal>
