<script module>
  export { default as layout } from "../designs/Layout.svelte";
</script>

<script>
  import { useForm } from "@inertiajs/svelte";
  import Form from "$lib/components/Form.svelte";
  import NavBar from "$lib/components/NavBar.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";

  let { design_link, icon_options } = $props();
  let isNew = $derived(!design_link.id);
  let formRef = $state();

  const form = useForm({
    target: design_link.target,
    name: design_link.name,
    icon: design_link.icon,
    subject_url: design_link.subject_url,
  });
</script>

<NavBar title={isNew ? "New Link" : "Edit Link"}>
  {#snippet leftAction({ isWorking })}
    <ActionLink text="Back" href="/design" {isWorking} iconProps={{ type: "chevron-left" }} />
  {/snippet}

  {#snippet rightAction({ isWorking })}
    <ActionLink text="Save" onclick={() => formRef.submit()} {isWorking} iconProps={{ type: "check" }} />
  {/snippet}
</NavBar>

<Form {form} bind:this={formRef} action={isNew ? "/design/links" : `/design/links/${design_link.id}`} method={isNew ? "post" : "patch"} class="space-y-1 p-4">
  {#if design_link.target === "header"}
    <InputField {form} name="name" placeholder={design_link.subject_name} required fieldProps={{ hint: `Name of the ${design_link.subject_name} section of your site` }} />
  {:else}
    <InputField {form} name="name" placeholder="Link name" required fieldProps={{ hint: "Shown in the footer of some templates" }} />
    <SelectField {form} name="icon" options={icon_options} fieldProps={{ hint: "Shown in the footer of some templates" }} />
    <InputField {form} name="subject_url" placeholder="https://www.your-url.com" required fieldProps={{ label: "URL", hint: "Accepts any URL or email address" }} />
  {/if}
</Form>
