<script>
  import { mx } from "$lib/utils";
  import Modal from "$lib/components/Modal.svelte";
  import Form from "$lib/components/Form.svelte";
  import StripeForm from "$lib/components/form/StripeForm.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { form, action, method = "get", formRef = $bindable(), title, leftAction, rightActionProps, stripeProps, children, ...props } = $props();
  let isSubmitting = $state(false);

  let formProps = $derived({
    action,
    method,
    ...props,
  });
</script>

<Modal {title} {leftAction} isWorking={isSubmitting}>
  {#snippet rightAction({ isWorking })}
    {#if rightActionProps !== false}
      <ActionLink {isWorking} showSpinner onclick={() => formRef.submit()} {...mx({ text: "Save", iconProps: { type: "check" } }, rightActionProps)} />
    {/if}
  {/snippet}

  {#if stripeProps}
    <StripeForm bind:this={formRef} bind:isSubmitting {...formProps} {...stripeProps}>
      {@render children?.()}
    </StripeForm>
  {:else}
    <Form bind:this={formRef} bind:isSubmitting {form} {...formProps}>
      {@render children?.()}
    </Form>
  {/if}
</Modal>
