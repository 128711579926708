<script>
  import { mx } from "$lib/utils";

  const SVG_REGEX = /<svg([^>]*)>/;
  const ATTRS_REGEX = /(\w+)(?:=(?:"([^"]*)"|'([^']*)'|(\S+)))?/g;
  const ATTRS_DEFAULT = { class: "inline-block fill-current" };

  let { src, ...attrsNew } = $props();
  let match = $derived(src.match(SVG_REGEX));
  let attrsStr = $derived(match[1].trim());
  let attrsOld = $derived(Object.fromEntries([...attrsStr.matchAll(ATTRS_REGEX)].map(([_, name, ...values]) => [name, values.find(Boolean)]).filter(([_, value]) => value)));
  let attrsMx = $derived(mx(ATTRS_DEFAULT, attrsOld, attrsNew));

  let attrs = $derived(
    Object.entries(attrsMx)
      .filter(([_, v]) => !!v)
      .map(([k, v]) => `${k}="${v}"`)
      .join(" "),
  );

  let svg = $derived(src.replace(SVG_REGEX, `<svg ${attrs}>`));
</script>

{@html svg}
