<script>
  import Index from "$lib/components/inventory/Index.svelte";
  import Grid from "$lib/components/inventory/Grid.svelte";

  let { albums, ...props } = $props();
</script>

<Index resources={albums} {...props}>
  <Grid resources={albums} />
</Index>
