<script>
  import { mx } from "$lib/utils";
  import Icon from "./Icon.svelte";

  let { iconProps, ...props } = $props();

  let propsMx = $derived(
    mx(
      {
        class: "size-12 rounded-full overflow-hidden inline-flex items-center justify-center bg-st-950 dark:bg-st-50 text-st-50 dark:text-st-950",
      },
      props,
    ),
  );
</script>

<div {...propsMx}>
  <Icon {...mx({ class: "size-4" }, iconProps)} />
</div>
