<script>
  import { cn, mx, humanizeUrl } from "$lib/utils";
  import Icon from "$lib/components/Icon.svelte";
  import Menu from "$lib/components/Menu.svelte";

  const qrcodeFormats = ["svg", "png"];

  let { label, url, qrcodeUrl, buttonProps, copyProps, qrButtonProps, iconProps, ...props } = $props();
  let showUrl = $derived(label !== url);
  let copyRef = $state();
  let copyTimeout = $state();

  let qrcodeItems = $derived(
    qrcodeUrl ? qrcodeFormats.map((format) => ({ text: format.toUpperCase(), href: `${qrcodeUrl}.${format}`, target: "_blank", iconProps: { type: "arrow-down-to-line" } })) : [],
  );

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    copyRef.textContent = "Copied";
    clearTimeout(copyTimeout);
    copyTimeout = setTimeout(() => (copyRef.textContent = "Copy"), 1000);
  };
</script>

<div {...mx({ class: "flex gap-1 h-14" }, props)}>
  <button
    onclick={copyUrl}
    {...mx({ class: "grow bg-st-200 rounded-md flex items-center gap-4 px-4 py-3 overflow-hidden hover:bg-accent hover:text-white transition-colors group" }, buttonProps)}
  >
    <Icon {...mx({ type: "supertape", class: "size-4 flex-none" }, iconProps)} />

    <div class={cn("grow overflow-hidden text-left leading-none", showUrl ? "gradient-mask-r-60" : "gradient-mask-r-80")}>
      <p class="whitespace-nowrap">{humanizeUrl(label)}</p>

      {#if showUrl}
        <p class="whitespace-nowrap font-mono text-xs text-st-500 transition-colors group-hover:text-white/50">{humanizeUrl(url)}</p>
      {/if}
    </div>

    <div bind:this={copyRef} {...mx({ class: "font-mono uppercase text-sm text-accent group-hover:text-white transition-colors" }, copyProps)}>Copy</div>
  </button>

  {#if qrcodeItems.length}
    <Menu items={qrcodeItems} {...mx({ class: "flex-none bg-st-200 hover:bg-accent hover:text-white rounded-md flex items-center justify-center size-14" }, qrButtonProps)}>
      <Icon type="qrcode" class="size-4" />
    </Menu>
  {/if}
</div>
