<script>
  import { onMount } from "svelte";
  import { Image } from "@unpic/svelte";
  import Welcome from "./Welcome.svelte";
  import Visitors from "./Visitors.svelte";
  import StatsList from "./StatsList.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { visitors, inventory, platforms, referrers, countries, welcome, site_created } = $props();

  onMount(() => {
    if (site_created) window.zaraz?.track("site_created", site_created);
  });
</script>

{#if welcome}
  <Welcome {...welcome} />
{/if}

<div class="space-y-2.5">
  <Visitors {...visitors} />

  <section class="grid gap-2.5 lg:grid-cols-2">
    <StatsList title="Inventory views" data="inventory" stats={inventory}>
      {#snippet image({ image_url })}
        {#if image_url}
          <Image src={image_url} alt="Image" width={40} height={40} class="size-10 rounded shadow-lg" />
        {:else}
          <div class="shadlow-lg flex size-10 items-center justify-center rounded bg-st-200">
            <Icon type="xmark" class="size-4 text-st-500" />
          </div>
        {/if}
      {/snippet}
    </StatsList>

    <StatsList title="Platform views" data="platforms" stats={platforms}>
      {#snippet image({ icon })}
        <Icon type={icon} class="size-4" />
      {/snippet}
    </StatsList>
  </section>

  <section class="grid gap-2.5 lg:grid-cols-2">
    <StatsList title="Visitor referrers" data="referrers" stats={referrers}>
      {#snippet image({ icon })}
        <Icon type={icon} class="size-4" />
      {/snippet}
    </StatsList>

    <StatsList title="Visitor countries" data="countries" stats={countries}>
      {#snippet image({ flag })}
        {flag}
      {/snippet}
    </StatsList>
  </section>
</div>
