<script>
  import { ArcElement, Chart, DoughnutController, Tooltip } from "chart.js";

  Chart.register(ArcElement, DoughnutController, Tooltip);

  const EMPTY_DOUGHNUT = {
    id: "emptyDoughnut",
    afterDraw(chart, _, options) {
      const { datasets } = chart.data;
      const { color, width, radiusDecrease } = options;
      let hasData = false;

      for (let i = 0; i < datasets.length; i += 1) {
        const dataset = datasets[i];
        hasData |= dataset.data.length > 0;
      }

      if (!hasData) {
        const {
          chartArea: { left, top, right, bottom },
          ctx,
        } = chart;

        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const r = Math.min(right - left, bottom - top) / 2;

        ctx.beginPath();
        ctx.lineWidth = width || 2;
        ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
        ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
        ctx.stroke();
      }
    },
  };

  let { metricSingular, metricPlural, labels, values, colors, ...props } = $props();

  let canvas = $state();
  let chart = $state();

  let config = $derived({
    type: "doughnut",
    data: {
      labels,
      datasets: [
        {
          label: metricPlural.toUpperCase(),
          data: values == [0] ? [] : values,
          backgroundColor: colors,
        },
      ],
    },
    options: {
      animation: false,
      borderWidth: 0,
      hoverOffset: 0,
      cutout: "85%",
      plugins: {
        tooltip: {
          displayColors: false,
          padding: 16,
          titleFont: { family: "ibm-plex-mono" },
          bodyFont: { family: "ibm-plex-mono" },
          callbacks: {
            title: (context) => context[0].label.toUpperCase(),
            label: (context) => `${new Intl.NumberFormat().format(context.parsed)} ${context.parsed === 1 ? metricSingular.toUpperCase() : metricPlural.toUpperCase()}`,
          },
        },
        emptyDoughnut: {
          color: "#d4d4d4",
          width: 16,
          radiusDecrease: 8,
        },
      },
    },
    plugins: [EMPTY_DOUGHNUT],
  });

  $effect(() => {
    chart = new Chart(canvas, config);
    return () => chart.destroy();
  });
</script>

<canvas bind:this={canvas} {...props}></canvas>
