<script>
  import Menu from "$lib/components/Menu.svelte";

  let { colOne, colTwo, colThree, actions = [] } = $props();
</script>

<tr class="flex h-12 items-center gap-3 font-mono">
  <td class="w-full truncate whitespace-nowrap lg:-mr-2 lg:w-[calc(50%+16px)] lg:flex-none">
    {@render colOne()}
  </td>

  <td class="hidden flex-1 basis-9 truncate whitespace-nowrap text-st-500 lg:table-cell">
    {@render colTwo()}
  </td>

  {#if colThree}
    <td class="hidden flex-1 basis-[166px] space-x-2 truncate whitespace-nowrap text-st-500 lg:table-cell">
      {@render colThree()}
    </td>
  {/if}

  {#if actions.length}
    <td class="flex-1 flex-none basis-[16px] truncate whitespace-nowrap py-2 text-st-500">
      <Menu items={actions} />
    </td>
  {/if}
</tr>
