<script>
  import { page } from "@inertiajs/svelte";
  import { Image } from "@unpic/svelte";
  import { cn } from "$lib/utils";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";
  import WorkingText from "./WorkingText.svelte";
  import WorkingIcon from "./WorkingIcon.svelte";

  let { backHref, buttonHref, buttonText, buttonMethod, onButtonClick, workingText = [], hideAvatar = false, hideBackground = false, showSkip = false, children } = $props();
  let { name, image_url } = $derived($page.props);

  let isWorking = $derived(!!workingText.length);
  let hasButton = $derived(buttonHref || onButtonClick);

  const buttonClass =
    "pointer-events-auto z-50 inline-flex h-16 w-[200px] items-center justify-center rounded-xl bg-st-950 text-st-50 shadow-xl shadow-st-950/30 transition-[colors,transform] hover:scale-110 active:scale-95 active:bg-st-700 dark:shadow-st-50/30";
</script>

<div class="z-20 flex w-full flex-1 justify-center pt-8">
  <div class="relative max-h-[91dvh] max-w-[725px] flex-1 rounded-xl bg-st-200">
    <nav class="absolute z-20 flex h-16 w-full justify-between p-4 sm:p-6">
      {#if !isWorking}
        <span class="w-16">
          {#if backHref}
            <ActionLink text="Back" href={backHref} iconProps={{ type: "chevron-left" }} />
          {/if}
        </span>

        {#if !hideAvatar && name}
          <Avatar {name} imageProps={{ src: image_url, width: 64, height: 64 }} class="-mt-1 size-12 shadow-xl shadow-st-950/30 sm:-mt-2 sm:size-16 dark:shadow-st-50/30" />
        {/if}

        <span class="w-16">
          {#if buttonHref && showSkip}
            <ActionLink text="Skip" href={buttonHref} iconProps={{ type: "chevron-right" }} class="flex-row-reverse text-st-500" />
          {/if}
        </span>
      {/if}
    </nav>

    {#if isWorking}
      <WorkingText text={workingText} />
    {:else}
      <div class={cn("flex size-full flex-col overflow-y-auto px-6 py-24 sm:px-14 sm:py-32", hasButton ? "!pb-32" : "!pb-0")}>
        {@render children()}
      </div>
    {/if}

    {#if isWorking || hasButton}
      <footer class="pointer-events-none absolute bottom-8 flex h-16 w-full items-center justify-center">
        {#if isWorking}
          <WorkingIcon />
        {:else if buttonHref}
          <Link href={buttonHref} method={buttonMethod} class={buttonClass}>
            {@render buttonContent()}
          </Link>
        {:else if onButtonClick}
          <button onclick={onButtonClick} class={buttonClass}>
            {@render buttonContent()}
          </button>
        {/if}
      </footer>
    {/if}
  </div>
</div>

{#if image_url && !hideBackground}
  <div class="absolute inset-0 z-10 size-full overflow-hidden">
    <Image src={image_url} alt={name} layout="fullWidth" class="size-full scale-125 blur-xl brightness-75" priority />
    <div class="grain size-full bg-black/10"></div>
  </div>
{/if}

{#snippet buttonContent()}
  {#if buttonText}
    <span class="font-poppins-medium text-xl">{buttonText}</span>
  {:else}
    <Icon type="arrow-right" class="size-8" />
  {/if}
{/snippet}
