<script>
  import { onMount } from "svelte";
  import Sortable from "sortablejs/modular/sortable.core.esm.js";
  import { mx } from "$lib/utils";

  let { onsort, children, ...props } = $props();
  let ref = $state();
  let sortable = $state();
  let sortableIds = $state();

  let sortableOptions = $derived({
    animation: 150,
    handle: ".handle",
    ghostClass: "opacity-20",
    store: {
      set: () => {
        let newIds = sortable.toArray();
        if (newIds.join(",") !== sortableIds.join(",")) onsort(newIds);
      },
    },
  });

  onMount(() => {
    if (!onsort) return;

    const stopChangeEvent = (event) => event.stopPropagation();
    ref.addEventListener("change", stopChangeEvent);

    sortable = new Sortable(ref, sortableOptions);
    sortableIds = sortable.toArray();
    ref.classList.add("is-sorted");

    return () => {
      ref.removeEventListener("change", stopChangeEvent);
      sortable.destroy();
    };
  });
</script>

<div bind:this={ref} {...mx({ class: "group/stack-list space-y-1" }, props)}>
  {@render children()}
</div>
