<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import Page from "./Page.svelte";

  let { has_albums, has_events, has_products, has_videos, has_socials } = $props();

  let workingText = $derived.by(() => {
    const result = ["Creating your site"];

    if (has_albums) result.push("Syncing your music");
    if (has_products) result.push("Syncing your merch");
    if (has_videos) result.push("Syncing your videos");
    if (has_events) result.push("Syncing your tour dates");
    if (has_socials) result.push("Connecting your socials");

    result.push("Applying your template");
    result.push("Finishing up");

    return result;
  });
</script>

<svelte:head>
  <title>Creating New Site - Supertape</title>
</svelte:head>

<Page {workingText} />
