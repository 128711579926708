<script>
  import { DOMAIN_INSTRUCTIONS_URL } from "$lib/const";
  import { cn } from "$lib/utils";
  import Pod from "$lib/components/detail/Pod.svelte";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Badge from "$lib/components/Badge.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import MiniPlaque from "./MiniPlaque.svelte";
  import domainsSvg from "$assets/images/settings/domains.svg?raw";

  let { domains, actions } = $props();
</script>

{#if domains.length}
  <Table title="Domains" {actions}>
    {#each domains as { name, icon, status, verified, actions: domainActions }}
      <TableRow actions={domainActions}>
        {#snippet colOne()}
          <div class="inline-flex items-center gap-8">
            <Icon type={icon} class="size-4 flex-none" />

            <div class="inline-flex w-full items-center gap-2 overflow-hidden">
              <span class="truncate font-sans text-base">{name}</span>

              {#if !verified}
                <ActionLink text="Connect" href={DOMAIN_INSTRUCTIONS_URL} class="flex-row-reverse gap-0.5 text-sm" iconProps={{ type: "arrow-up-right" }} />
              {/if}
            </div>
          </div>
        {/snippet}

        {#snippet colTwo()}
          <Badge>{status}</Badge>
        {/snippet}

        {#snippet colThree()}
          <div class="flex items-center gap-1">
            <Icon type="circle-small" dir="solid" class={cn("size-3", verified ? "text-[#2BE08F]" : "text-st-400")} />
            <span class={cn("font-mono text-sm uppercase", verified ? "text-[#19B56F]" : "text-st-500")}>{verified ? "Verified" : "Unverified"}</span>
          </div>
        {/snippet}
      </TableRow>
    {/each}
  </Table>
{:else}
  <Pod title="Domains" {actions}>
    <MiniPlaque
      title="Purchase or connect a domain"
      description="Get discounts on domains ending in .band, .live, and .info"
      src={domainsSvg}
      href="/domains/new"
      target="_modal"
    />
  </Pod>
{/if}
