<script>
  import { useForm } from "@inertiajs/svelte";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import Form from "$lib/components/Form.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  const form = useForm({
    domain_name: null,
  });

  let { search, sites } = $props();
</script>

<Modal title="Sites">
  {#snippet rightAction()}
    <ActionLink text="New" href="/sites/new" iconProps={{ type: "plus" }} />
  {/snippet}

  {#if search}
    <Form {form} method="post" action="/sites/search" class="mb-6">
      <InputField {form} name="domain_name" placeholder="URL, domain, or subdomain" required autofocus fieldProps={{ label: "Search" }} />
    </Form>
  {/if}

  <StackList>
    {#each sites as site}
      <StackListItem as="Link" prefetch={false} href={site.href}>
        {#snippet image()}
          <Avatar name={site.name} imageProps={{ src: site.image_url }} />
        {/snippet}

        {#snippet title()}
          {site.name}
        {/snippet}

        {#snippet subtitle()}
          {site.status}
        {/snippet}

        {#snippet action()}
          <Icon type="arrow-right-arrow-left" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
