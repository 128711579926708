<script>
  import Index from "$lib/components/inventory/Index.svelte";
  import Grid from "$lib/components/inventory/Grid.svelte";

  let { products, ...props } = $props();
</script>

<Index resources={products} {...props}>
  <Grid resources={products} />
</Index>
