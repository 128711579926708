<script>
  import { cn, mx } from "$lib/utils";
  import Icon from "./Icon.svelte";
  import Link from "./Link.svelte";

  let { as = "div", image, title, subtitle, meta, action, disabled = false, contentProps, ...props } = $props();
  let isLink = $derived(["Link", "a", "button"].includes(as));

  let propsMx = $derived(
    mx({ class: cn("block bg-st-200 rounded-md w-full", isLink && "hover:bg-st-300 transition-colors max-lg:active:bg-st-300", disabled && "pointer-events-none") }, props),
  );
</script>

{#if isLink}
  <Link as={as === "Link" ? undefined : as} {...propsMx}>
    {@render content()}
  </Link>
{:else}
  <svelte:element this={as} {...propsMx}>
    {@render content()}
  </svelte:element>
{/if}

{#snippet content()}
  <div {...mx({ class: cn("grid w-full grid-cols-[auto_max-content] gap-4 p-4", meta && "sm:grid-cols-2", disabled && "opacity-50") }, contentProps)}>
    <div class="flex min-w-0 items-center gap-4">
      {#if image}
        <div class="flex-shrink-0">
          {@render image()}
        </div>
      {/if}

      <div class="min-w-0 text-left">
        {#if title}
          <h3 class="truncate">
            {@render title()}
          </h3>
        {/if}

        {#if subtitle}
          <p class="mt-0 truncate font-mono text-xs text-st-500">
            {@render subtitle()}
          </p>
        {/if}
      </div>
    </div>

    <div class={cn("flex items-center gap-4", meta ? "justify-between" : "justify-end")}>
      {#if meta}
        <div class="hidden truncate text-left sm:block">
          {@render meta()}
        </div>
      {/if}

      <div class="handle hidden cursor-grab group-[.is-sorted:has(>:nth-child(2))]/stack-list:block">
        <Icon type="grip-dots" class="size-4" />
      </div>

      {#if action}
        {@render action()}
      {/if}
    </div>
  </div>
{/snippet}
