<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import InfoBlock from "$lib/components/InfoBlock.svelte";
  import Link from "$lib/components/Link.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { current, delete_at } = $props();
</script>

<svelte:head>
  <title>Canceled - Supertape</title>
</svelte:head>

<InfoBlock title="Your subscription is canceled" iconProps={{ type: "face-frown-slight" }}>
  {#if current.user.owner}
    <section class="space-y-4 text-center">
      <p>
        If you'd like to keep your site for <span class="font-poppins-bold">{current.site.name}</span>, which we hope you do, subscribe now below. If you have any questions or
        issues holding you back, just let us know.
      </p>

      <p>
        Otherwise, if you don't want to keep your site, you can either do nothing and it'll be deleted automatically on <span class="font-poppins-bold">{delete_at}</span>, or you
        can delete it now.
      </p>
    </section>

    <section class="flex justify-center">
      <Link href="/billing/plan" target="_modal" class="w-96 rounded-md bg-accent px-4 py-3 text-center text-white transition-opacity hover:opacity-50">Subscribe</Link>
    </section>

    <section class="flex justify-center">
      <ActionLink
        href="/settings/delete"
        target="_modal"
        text="Delete"
        iconProps={{ type: "skull-crossbones" }}
        class="text-st-500 transition-colors hover:text-error hover:opacity-100"
      />
    </section>
  {:else}
    <section class="space-y-4 text-center">not owner</section>
  {/if}
</InfoBlock>
