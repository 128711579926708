<script>
  import { mx } from "$lib/utils";

  let { items, ...props } = $props();
  props = mx({ class: "bg-st-200 divide-y divide-st-300 rounded-lg" }, props);
</script>

<dl {...props}>
  {#each items as { label, value }}
    <div class="flex items-center justify-between gap-2 px-5 py-3.5">
      <dt class="font-mono text-sm uppercase text-st-500">{label}</dt>
      <dd>
        {#if typeof value === "function"}
          {@render value()}
        {:else}
          {@html value}
        {/if}
      </dd>
    </div>
  {/each}
</dl>
