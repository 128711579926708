<script>
  import { humanizeNumber } from "$lib/utils";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";

  let { title, stats, image } = $props();
  let total = $derived(stats ? stats.reduce((sum, stat) => sum + stat.count, 0) : null);
</script>

<div class="min-w-0 rounded-md bg-st-200 p-4 pt-[13px] lg:p-6 lg:pt-5">
  <h2 class="font-poppins-medium mb-0.5 text-xs tracking-wide text-st-500">{title}</h2>

  <p class="mb-8 h-9 text-3xl tracking-tight">
    {#if total}
      {humanizeNumber(total)}
    {/if}
  </p>

  <div class="h-[270px] min-w-0 snap-y snap-mandatory space-y-1 overflow-auto scrollbar-hide">
    {#if stats.length}
      {#each stats as stat}
        <div class="relative flex h-14 min-w-0 snap-start items-center justify-between gap-2 overflow-hidden rounded-md bg-st-50/50 p-2">
          <div class="z-10 flex size-10 flex-none items-center justify-center">
            {@render image(stat)}
          </div>

          <div class="z-10 min-w-0 grow truncate">{stat.name}</div>
          <div class="z-10 flex-none px-2 font-mono">{humanizeNumber(stat.count)}</div>
          <div class="absolute left-0 top-0 h-full bg-st-50" style:width={`${Math.round((stat.count / total) * 100)}%`}></div>
        </div>
      {/each}
    {:else}
      <InfoSnippet text={`No ${title.toLowerCase()}`} iconProps={{ type: "empty-set" }} class="-mt-5 h-full" />
    {/if}
  </div>
</div>
