<script>
  import { mx } from "$lib/utils";
  import Link from "./Link.svelte";
  import Icon from "./Icon.svelte";

  let { href, text, iconProps, ...props } = $props();

  props = mx(
    {
      class:
        "flex items-center justify-center gap-2 border border-st-950 rounded-xl px-4 py-2 w-min mx-auto hover:border-accent hover:bg-accent hover:text-white active:opacity-50 active:transition-opacity transition-colors font-mono uppercase",
    },
    props,
  );
</script>

{#snippet content()}
  {#if text}
    <span>{text}</span>
  {/if}

  {#if iconProps?.type}
    <Icon {...mx({ class: "size-4" }, iconProps)} />
  {/if}
{/snippet}

{#if href}
  <Link {href} {...props}>
    {@render content()}
  </Link>
{:else}
  <button {...props}>
    {@render content()}
  </button>
{/if}
