<script>
  import InfoAlert from "$lib/components/InfoAlert.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";
  import Album from "./Album.svelte";
  import Links from "$lib/components/detail/Links.svelte";

  let { product } = $props();
</script>

<div class="space-y-2.5">
  {#if product.hidden}
    <InfoAlert text="This product is hidden" iconProps={{ type: "eye-slash" }} />
  {/if}

  <Meta name={product.name} imageUrl={product.image_url} meta={product.meta} info={product.info} infoPlaceholder="Add a description, dimensions, shipping, etc." />

  {#if product.album}
    <Album album={product.album} />
  {/if}

  <Links links={product.links} />
</div>
