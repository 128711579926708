<script>
  import { mx, humanize } from "$lib/utils";
  import Field from "../Field.svelte";
  import Link from "../../Link.svelte";
  import Icon from "../../Icon.svelte";

  let { form, name, iconProps, fieldProps, children, ...props } = $props();
</script>

<Field {form} {name} {...fieldProps} noContainer>
  <Link {...mx({ class: "flex items-center justify-between gap-4 rounded-md bg-st-200 px-4 py-3 transition-colors hover:bg-accent group" }, props, fieldProps?.containerProps)}>
    <div class="whitespace-nowrap text-st-950/60 group-hover:text-st-50/60">{fieldProps?.label || humanize(name)}</div>

    <div class="flex items-center justify-end gap-1 whitespace-nowrap leading-none group-hover:text-white">
      <div class="max-w-[165px] truncate">
        {#if children}
          {@render children()}
        {:else}
          {$form[name]}
        {/if}
      </div>

      {#if iconProps !== false}
        <Icon {...mx({ type: "chevron-right", class: "size-3" }, iconProps)} />
      {/if}
    </div>
  </Link>
</Field>
