<script>
  import { mx } from "$lib/utils";
  import { WWW_URL } from "$lib/const";
  import Link from "$lib/components/Link.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Actions from "./Actions.svelte";
  import ModalRoot from "./ModalRoot.svelte";
  import ProgressBar from "./ProgressBar.svelte";
  import Toast from "./Toast.svelte";

  let { current, headerProps, actionsProps, children } = $props();
  let loggedIn = $derived(!!current.user);

  actionsProps ||= {
    framedIconProps: {
      class: "size-8 lg:mt-1 transition-colors bg-st-200 hover:bg-st-300 text-st-950",
      iconProps: { type: "ellipsis", class: "size-6" },
    },
  };
</script>

<header {...mx({ class: "fixed left-0 top-0 z-50 flex w-full justify-between px-3 pt-1.5" }, headerProps)}>
  <Link href={loggedIn ? "/" : WWW_URL} class="group mt-1 leading-none transition-colors hover:text-accent">
    <Icon type="supertape" class="size-[27px]" />
    <span class="relative bottom-[.1em] font-mono uppercase tracking-wide">Supertape</span>
  </Link>

  <Actions maxExpanded={1} {...actionsProps} />
</header>

<main class="flex min-h-[100dvh] flex-col items-center justify-center p-4">
  {@render children()}
</main>

<ModalRoot />
<ProgressBar />
<Toast />
