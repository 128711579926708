<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";

  let { action, video_link, video_options } = $props();

  const form = useForm(video_link);
</script>

<FormModal {form} title="Edit Link" {action} method="patch">
  <SelectField {form} name="video_id" options={video_options} />
</FormModal>
