<script>
  let { src, width, height, artboard, autoplay = true, ...props } = $props();
  let canvas;
  let rive;

  const create = async () => {
    const { Rive } = await import("@rive-app/canvas-lite");

    rive = new Rive({
      src,
      canvas,
      autoplay,
      artboard,
      onLoad: () => rive.resizeDrawingSurfaceToCanvas(),
    });
  };

  $effect(() => {
    create();
    return () => rive.cleanup();
  });
</script>

<canvas bind:this={canvas} width={width * 2} height={height * 2} style:max-width={`${width}px`} style:max-height={`${height}px`} {...props}></canvas>
