<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  const form = useForm({
    email: null,
  });
</script>

<FormModal {form} title="New Invite" action="/invites" method="post">
  <InputField {form} name="email" type="email" placeholder="email@example.com" required />
</FormModal>
