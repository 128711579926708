<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { site_link, platform } = $props();

  const form = useForm(site_link);
</script>

<FormModal {form} title="New Platform" action="/platforms" method="post" leftAction="Back">
  <InfoHeader title={platform.name} class="mb-8" iconProps={{ type: platform.icon }} />
  <InputField {form} name="source_url" type="url" placeholder={platform.placeholder} required fieldProps={{ label: "URL" }} />
</FormModal>
