<script>
  import Index from "$lib/components/inventory/Index.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Badge from "$lib/components/Badge.svelte";

  let { events, ...props } = $props();
</script>

<Index resources={events} {...props}>
  <StackList>
    {#each events as event}
      <StackListItem as="Link" href={event.href} class={event.hidden && "opacity-50"} contentProps={{ class: "sm:p-6" }}>
        {#snippet image()}
          <div class="flex size-16 flex-col items-center justify-center gap-1.5 rounded-2xl bg-st-950 text-st-50 shadow-xl">
            <div class="font-mono text-xs uppercase leading-none tracking-wider text-st-300">{event.month}</div>
            <div class="text-xl leading-none">{event.day}</div>
          </div>
        {/snippet}

        {#snippet title()}
          {event.name}
        {/snippet}

        {#snippet subtitle()}
          {event.venue_location}
        {/snippet}

        {#snippet meta()}
          <Badge>{event.venue_name}</Badge>
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-5" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Index>
