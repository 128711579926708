<script>
  import Preview from "./Preview.svelte";
  import List from "./List.svelte";
  import Plaque from "$lib/components/Plaque.svelte";
  import Unlock from "$lib/components/Unlock.svelte";
  import unlockVideoUrl from "$assets/videos/plans/pro_posts.mp4";
  import unlockPosterUrl from "$assets/images/plans/pro_posts_poster.jpg";

  let { enabled, posts, preview_url } = $props();
</script>

{#if enabled}
  <div class="flex h-[calc(100vh-200px)] flex-1 gap-2.5 lg:flex-none">
    <Preview {preview_url} />

    {#if posts.length}
      <List {posts} />
    {:else}
      <Plaque
        title="Posts"
        description="Use posts to highlight important links on your homepage"
        iconProps={{ type: "message" }}
        linkProps={{ href: "/posts/new", target: "_modal", iconProps: { type: "plus" } }}
        class="basis-full lg:basis-1/2"
      />
    {/if}
  </div>
{:else}
  <Unlock title="Unlock Posts with Pro" description="Upgrade now to highlight releases and links on your homepage" videoUrl={unlockVideoUrl} posterUrl={unlockPosterUrl} />
{/if}
