<script>
  import { cn } from "$lib/utils";
  import Menu from "$lib/components/Menu.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { site_links } = $props();
</script>

<div class="grid gap-2.5 min-[440px]:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
  {#each site_links as site_link}
    <div class="flex flex-col items-center gap-6 rounded-md bg-st-200 px-4 py-2.5">
      <div class="flex w-full items-center justify-between gap-2">
        <h2 class="truncate">{site_link.name}</h2>

        <Menu items={site_link.actions} iconProps={{ class: "size-5" }} />
      </div>

      <div class="flex w-full items-center gap-6 max-[440px]:pb-2 max-[440px]:pl-2 min-[440px]:flex-col">
        <FramedIcon class="size-24 shrink-0 max-[440px]:size-28" iconProps={{ type: site_link.icon, class: "max-[440px]:size-10 size-9" }} />

        <ul class="w-full space-y-0.5">
          {@render syncItem({ label: "Profiles", plan_supported: true, platform_supported: true, enabled: true })}
          {@render syncItem({ label: "Albums", ...site_link.albums })}
          {@render syncItem({ label: "Products", ...site_link.products })}
          {@render syncItem({ label: "Videos", ...site_link.videos })}
          {@render syncItem({ label: "Events", ...site_link.events })}
          {@render syncItem({ label: "Newsletters", ...site_link.newsletters })}
          {@render syncItem({ label: "Audience", ...site_link.audience })}
        </ul>
      </div>
    </div>
  {/each}
</div>

{#snippet syncItem({ label, plan_supported, platform_supported, enabled })}
  <li class="flex items-center justify-between">
    <span class={cn("font-mono text-sm uppercase tracking-wider", !platform_supported && "text-st-400")}>{label}</span>

    {#if plan_supported && platform_supported}
      <Icon type="circle-small" dir="solid" class={cn("size-3", enabled ? "text-[#2BE08F]" : "text-st-300")} />
    {:else if !platform_supported}
      <Icon type="circle-small" dir="solid" class="size-3 text-st-300" />
    {:else}
      <Link href="/billing/plan" target="_modal" class="transition-colors hover:text-accent">
        <Icon type="st-lock-sm" class="size-3" />
      </Link>
    {/if}
  </li>
{/snippet}
