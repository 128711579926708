<script>
  import Modal from "$lib/components/Modal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import InfoList from "$lib/components/InfoList.svelte";

  let { owned_site_names } = $props();

  let items = $derived(owned_site_names.map((name) => ({ label: "Owner", value: name })));
</script>

<Modal title="Delete Profile">
  <div class="space-y-8">
    <InfoHeader title="Owners can't be deleted" iconProps={{ type: "xmark-large" }} />

    <InfoCopy>
      You'll need to transfer or delete the following {owned_site_names.length == 1 ? "site" : "sites"} before you can delete your profile.
    </InfoCopy>

    <InfoList {items} />
  </div>
</Modal>
