<script>
  import { Image } from "@unpic/svelte";
  import Pod from "$lib/components/detail/Pod.svelte";
  import Info from "$lib/components/detail/Info.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";

  let { name, info, country_name, image_url } = $props();
</script>

<Pod title={name} actions={[{ text: "Edit", href: "/settings/edit", target: "_modal", iconProps: { type: "pen" } }]} headerAttrs={{ class: "lg:col-span-12" }}>
  <div class="flex h-full flex-col-reverse justify-between gap-8 sm:flex-row">
    <div class="flex h-full flex-1 flex-col sm:gap-2">
      <p class="relative -top-5 text-center font-mono text-xs uppercase tracking-wider text-st-500 sm:text-left">{country_name}</p>
      <Info {info} placeholder="Add an artist bio, contact info, management, etc." class="h-fit max-h-32 sm:max-w-[90%] lg:max-h-56" />
    </div>

    <div class="mx-auto aspect-square size-44 flex-none sm:p-2 lg:size-60">
      {#if image_url}
        <Image src={image_url} alt={name} width={224} height={224} class="rounded-md shadow-xl" />
      {:else}
        <div class="flex aspect-square w-full items-center justify-center rounded-md border border-st-300">
          <InfoSnippet text="No image" iconProps={{ type: "xmark" }} />
        </div>
      {/if}
    </div>
  </div>
</Pod>
