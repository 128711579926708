<script>
  import { mx } from "$lib/utils";
  import Form from "$lib/components/Form.svelte";
  import NavBar from "$lib/components/NavBar.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";

  let { form, title, containerProps, children, ...props } = $props();
</script>

<Form {form} {...mx({ method: "patch", action: "/design", class: "space-y-0" }, props)}>
  {#if title}
    <NavBar {title}>
      {#snippet leftAction({ isWorking })}
        <ActionLink text="Back" href="/design" {isWorking} iconProps={{ type: "chevron-left" }} />
      {/snippet}
    </NavBar>
  {/if}

  <div {...mx({ class: "p-4" }, containerProps)}>
    {@render children()}
  </div>
</Form>
