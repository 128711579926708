<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import InfoBlock from "$lib/components/InfoBlock.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";

  let { sites, links } = $props();
</script>

<svelte:head>
  <title>Supertape</title>
</svelte:head>

<InfoBlock title={sites.length ? "Where would you like to go next?" : "What would you like to do next?"} size="base" iconProps={{ type: "signs-post" }}>
  <StackList>
    {#each sites as { name, href, image_url }}
      <StackListItem as="Link" {href} prefetch={false}>
        {#snippet image()}
          <Avatar {name} imageProps={{ src: image_url }} />
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {:else}
      {#each links as { label, href, target, iconProps }}
        <StackListItem as="Link" {href} {target}>
          {#snippet image()}
            <FramedIcon {iconProps} />
          {/snippet}

          {#snippet title()}
            {label}
          {/snippet}

          {#snippet action()}
            <Icon type="chevron-right" class="size-4" />
          {/snippet}
        </StackListItem>
      {/each}
    {/each}
  </StackList>
</InfoBlock>
