<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";
  import SelectField from "$lib/components/form/fields/SelectField.svelte";

  let { action, resource_name, query, type_id, type_options, platform_id, platform_options, status, status_options } = $props();

  const form = useForm({
    query,
    type_id,
    platform_id,
    status,
  });
</script>

<FormModal {form} {action} title="Filter" rightActionProps={{ text: "Done" }}>
  <InputField {form} name="query" type="text" placeholder={`${resource_name} name`} required fieldProps={{ label: "Search" }} />
  <SelectField {form} name="type_id" options={type_options} />
  <SelectField {form} name="platform_id" options={platform_options} />
  <SelectField {form} name="status" options={status_options} />
</FormModal>
