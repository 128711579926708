<script>
  import { Image } from "@unpic/svelte";
  import { mx } from "$lib/utils";
  import Link from "$lib/components/Link.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";

  let { resources, imageProps, ...props } = $props();
</script>

<div {...mx({ class: "grid gap-x-4 lg:gap-x-8 gap-y-8 lg:gap-y-16 peer-[]:mt-16 grid-cols-2 md:grid-cols-3 lg:grid-cols-4" }, props)}>
  {#each resources as resource}
    <Link href={resource.href} class="group flex flex-col gap-4 transition-opacity max-lg:active:opacity-70">
      <div class="relative overflow-hidden rounded-md">
        {#if resource.hidden}
          <div class="absolute inset-0 flex items-center justify-center bg-black/50 text-white">
            <InfoSnippet text="Hidden" iconProps={{ type: "eye-slash" }} />
          </div>
        {/if}

        <Image {...mx({ src: resource.image_url, alt: resource.name, width: 232, height: 232, class: "rounded-md" }, imageProps)} />
      </div>

      <div>
        <h2 class="line-clamp-2 leading-tight transition-colors group-hover:text-accent">{resource.name}</h2>
        <p class="font-mono text-sm text-st-500">{resource.type} &#x2022; {resource.year}</p>
      </div>
    </Link>
  {/each}
</div>
