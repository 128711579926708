<script>
  import { page } from "@inertiajs/svelte";
  import { Toaster, toast } from "svelte-sonner";

  let { notice, alert } = $derived($page.props.flash);

  $effect(() => {
    if (notice) toast.success(notice);
    if (alert) toast.error(alert);
  });
</script>

<Toaster
  offset="33px"
  toastOptions={{
    classes: {
      toast: "font-mono uppercase text-white border-0",
      success: "bg-accent",
      error: "bg-error",
    },
  }}
/>
