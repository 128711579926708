<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  let { email } = $props();

  const form = useForm({
    email_confirmation: null,
  });

  const transformData = (data) => {
    return { user: data };
  };
</script>

<FormModal {form} {transformData} title="Delete Profile" action="/user" method="delete" class="space-y-8" rightActionProps={{ text: "Confirm", class: "text-error" }}>
  <InfoHeader title="Delete your profile?" iconProps={{ type: "skull-crossbones" }} />

  <InfoCopy>
    Enter <em>{email}</em> below to confirm you want to permanently delete your profile.
    <span class="whitespace-nowrap text-error">Be careful, there is no undo!</span>
  </InfoCopy>

  <InputField {form} name="email_confirmation" type="email" placeholder={email} autocomplete="off" required fieldProps={{ label: "Email" }} />
</FormModal>
