<script>
  import { cn, mx } from "$lib/utils";
  import Link from "./Link.svelte";
  import Icon from "./Icon.svelte";

  let { text, href, iconProps, ...props } = $props();

  let propsMx = $derived(
    mx(
      {
        class: cn("bg-st-200 rounded-md flex items-center p-4 gap-4 justify-center font-mono uppercase", href && "hover:bg-st-300 transition-colors"),
      },
      props,
    ),
  );
</script>

{#snippet content()}
  <Icon {...mx({ class: "flex-none size-4" }, iconProps)} />
  <span>{text}</span>
{/snippet}

{#if href}
  <Link {href} {...propsMx}>
    {@render content()}
  </Link>
{:else}
  <div {...propsMx}>
    {@render content()}
  </div>
{/if}
