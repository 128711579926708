<script>
  import { router } from "@inertiajs/svelte";

  let { text = [] } = $props();
  let index = $state(0);
  let cycling = $state(false);
  let hiding = $state(false);
  let line = $derived(text[index]);
  let pollInterval;
  let nextInterval;

  const startPoll = () => (pollInterval = setInterval(poll, 1750));
  const stopPoll = () => clearInterval(pollInterval);
  const poll = () => router.reload();

  const startNext = () => (nextInterval = setInterval(next, 3000));
  const stopNext = () => clearInterval(nextInterval);

  const next = async () => {
    if (index === text.length - 1) {
      stopNext();
    } else {
      cycling = true;
      hiding = true;
      await new Promise((resolve) => setTimeout(resolve, 100));
      index++;
      hiding = false;
    }
  };

  $effect(() => {
    startPoll();
    startNext();

    return () => {
      stopPoll();
      stopNext();
    };
  });
</script>

<div
  class="font-poppins-medium flex size-full animate-pulse items-center justify-center overflow-hidden text-center text-[7vw] leading-normal tracking-tighter text-st-400 sm:text-[40px]"
>
  <p class={cycling && (hiding ? "!duration-100 animate-out fade-out slide-out-to-bottom" : "!duration-500 animate-in fade-in slide-in-from-bottom")}>
    {line}
  </p>
</div>
