<script>
  import Pod from "$lib/components/detail/Pod.svelte";
  import Table from "$lib/components/detail/Table.svelte";
  import TableRow from "$lib/components/detail/TableRow.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Badge from "$lib/components/Badge.svelte";
  import MiniPlaque from "./MiniPlaque.svelte";
  import toolsSvg from "$assets/images/settings/tools.svg?raw";

  let { tool_links, actions } = $props();
</script>

{#if tool_links.length}
  <Table title="Analytics" {actions}>
    {#each tool_links as { name, icon, purpose, actions: toolLinkActions }}
      <TableRow actions={toolLinkActions}>
        {#snippet colOne()}
          <div class="inline-flex items-center gap-8">
            <Icon type={icon} class="size-4 flex-none" />

            <div class="inline-flex w-full items-center gap-2 overflow-hidden">
              <span class="font-sans text-base">{name}</span>
            </div>
          </div>
        {/snippet}

        {#snippet colTwo()}
          <Badge class="block w-[95px] text-center">{purpose}</Badge>
        {/snippet}
      </TableRow>
    {/each}
  </Table>
{:else}
  <Pod title="Analytics" {actions}>
    <MiniPlaque
      title="Connect third-party tools and pixels"
      description="Measure ad campaigns and dive deeper into your site's usage"
      src={toolsSvg}
      href="/tools/new"
      target="_modal"
    />
  </Pod>
{/if}
