<script>
  import SVG from "$lib/components/SVG.svelte";

  const ICONS = import.meta.glob("$assets/images/icons/**/*.svg", { query: "?raw", eager: true });

  const BRANDS = [
    "apple",
    "bandcamp",
    "bluesky",
    "brave",
    "cc-amex",
    "cc-diners-club",
    "cc-discover",
    "cc-jcb",
    "cc-mastercard",
    "cc-visa",
    "chrome",
    "cloudflare",
    "edge",
    "facebook",
    "firefox",
    "google",
    "instagram",
    "mailchimp",
    "meta",
    "opera",
    "reddit",
    "safari",
    "shopify",
    "soundcloud",
    "spotify",
    "threads",
    "tiktok",
    "tumblr",
    "twitch",
    "vimeo-v",
    "wikipedia-w",
    "x-twitter",
    "yahoo",
    "yandex",
    "youtube",
  ];

  const CUSTOMS = ["audiomack", "bandsintown", "bigcartel", "deezer", "gumroad", "link-stripe", "musicbrainz", "songkick", "st-lock-sm", "supertape", "tidal"];
  const FALLBACK = ICONS["/images/icons/light/globe.svg"];

  let { type, dir = "light", ...props } = $props();

  if (type == "apple-music") {
    type = "apple";
  }

  if (BRANDS.includes(type)) {
    dir = "brands";
  } else if (CUSTOMS.includes(type)) {
    dir = "custom";
  }

  let src = $derived((ICONS[`/images/icons/${dir}/${type}.svg`] || FALLBACK).default);
</script>

<SVG {src} {...props} />
