<script>
  import InfoAlert from "$lib/components/InfoAlert.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";
  import Venue from "./Venue.svelte";
  import Links from "$lib/components/detail/Links.svelte";

  let { event } = $props();
</script>

<div class="space-y-2.5">
  {#if event.hidden}
    <InfoAlert text="This event is hidden" iconProps={{ type: "eye-slash" }} />
  {/if}

  <Meta name={event.name} imageUrl={event.image_url} meta={event.meta} info={event.info} infoPlaceholder="Add a description, credits, accolades, etc." isevent />

  <Venue {event} />

  <Links links={event.links} />
</div>
