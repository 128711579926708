<script>
  import SVG from "$lib/components/SVG.svelte";
  import Link from "$lib/components/Link.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  let { src, title, description, ...linkProps } = $props();
</script>

<div class="flex flex-col items-center p-4">
  <SVG {src} />
  <Link {...linkProps} class="block text-lg text-accent transition-opacity hover:opacity-50">{title}</Link>
  <InfoCopy class="max-w-xs">{description}</InfoCopy>
</div>
