<script>
  import Site from "./Site.svelte";
  import Domains from "./Domains.svelte";
  import Tools from "./Tools.svelte";
  import Team from "./Team.svelte";
  import Billing from "./Billing.svelte";

  let { current, site, domains, domains_actions, tool_links, tool_links_actions, team_members, team_actions, ...billingProps } = $props();
</script>

<div class="space-y-2.5">
  <Site {...site} />

  <Domains {domains} actions={domains_actions} />

  <Tools {tool_links} actions={tool_links_actions} />

  <Team {team_members} actions={team_actions} />

  {#if current.user.owner}
    <Billing {site} {...billingProps} />
  {/if}
</div>
