<script>
  import { onMount } from "svelte";
  import { Editor } from "@tiptap/core";
  import Document from "@tiptap/extension-document";
  import Paragraph from "@tiptap/extension-paragraph";
  import HardBreak from "@tiptap/extension-hard-break";
  import Placeholder from "@tiptap/extension-placeholder";
  import Text from "@tiptap/extension-text";
  import Bold from "@tiptap/extension-bold";
  import Italic from "@tiptap/extension-italic";
  import BulletList from "@tiptap/extension-bullet-list";
  import OrderedList from "@tiptap/extension-ordered-list";
  import ListItem from "@tiptap/extension-list-item";
  import Heading from "@tiptap/extension-heading";
  import Link from "@tiptap/extension-link";
  import { cn } from "$lib/utils";
  import Field from "../Field.svelte";
  import Icon from "../../Icon.svelte";

  let { form, name, placeholder, fieldProps, ...props } = $props();
  let element = $state();
  let editor = $state();

  let boldEnabled = $state(true);
  let boldActive = $state(false);
  let italicEnabled = $state(true);
  let italicActive = $state(false);
  let headingEnabled = $state(true);
  let headingActive = $state(false);
  let olEnabled = $state(true);
  let olActive = $state(false);
  let ulEnabled = $state(true);
  let ulActive = $state(false);
  let linkEnabled = $state(true);
  let linkActive = $state(false);

  const updateControls = () => {
    boldEnabled = editor.can().chain().focus().toggleBold().run();
    boldActive = editor.isActive("bold");
    italicEnabled = editor.can().chain().focus().toggleItalic().run();
    italicActive = editor.isActive("italic");
    headingEnabled = editor.can().chain().focus().toggleHeading({ level: 2 }).run();
    headingActive = editor.isActive("heading", { level: 2 });
    olEnabled = editor.can().chain().focus().toggleOrderedList().run();
    olActive = editor.isActive("orderedList");
    ulEnabled = editor.can().chain().focus().toggleBulletList().run();
    ulActive = editor.isActive("bulletList");
    linkEnabled = editor.can().chain().focus().extendMarkRange("link").unsetLink().run();
    linkActive = editor.isActive("link");
  };

  const setLink = () => {
    let href = window.prompt("Enter a URL or email address", editor.getAttributes("link").href);

    if (href === null) return;
    if (href.trim() === "") return editor.chain().focus().extendMarkRange("link").unsetLink().run();

    if (href.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      href = `mailto:${href}`;
    } else if (!href.startsWith("http") && href.includes(".")) {
      href = `https://${href}`;
    }

    try {
      new URL(href);
    } catch {
      alert("Invalid URL or email address");
      return;
    }

    editor.chain().focus().extendMarkRange("link").setLink({ href }).run();
  };

  onMount(() => {
    editor = new Editor({
      element,
      content: $form[name],
      extensions: [
        Document,
        Paragraph,
        HardBreak,
        Text,
        Bold,
        Italic,
        BulletList,
        OrderedList,
        ListItem,
        Heading.configure({
          levels: [2, 3, 4, 5],
        }),
        Link.configure({
          defaultProtocol: "https",
          protocols: ["http", "https"],
          openOnClick: false,
        }),
        Placeholder.configure({
          placeholder,
        }),
      ],
      onUpdate: () => {
        $form[name] = editor.getHTML();
      },
      onTransaction: () => {
        updateControls();
      },
      editorProps: {
        attributes: {
          class: "rich-text focus:outline-none",
        },
      },
      ...props,
    });
    return () => editor.destroy();
  });
</script>

<Field {form} {name} containerProps={{ class: "p-0 flex-wrap gap-0" }} labelProps={{ class: "px-4 py-3" }} {...fieldProps}>
  <div class="m-3 flex gap-1">
    {#key editor}
      {#if editor}
        {@render control({
          icon: "bold",
          active: boldActive,
          disabled: !boldEnabled,
          onclick: () => editor.chain().focus().toggleBold().run(),
        })}

        {@render control({
          icon: "italic",
          active: italicActive,
          disabled: !italicEnabled,
          onclick: () => editor.chain().focus().toggleItalic().run(),
        })}

        {@render control({
          icon: "heading",
          active: headingActive,
          disabled: !headingEnabled,
          onclick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
        })}

        {@render control({
          icon: "list-ul",
          active: ulActive,
          disabled: !ulEnabled,
          onclick: () => editor.chain().focus().toggleBulletList().run(),
        })}

        {@render control({
          icon: "list-ol",
          active: olActive,
          disabled: !olEnabled,
          onclick: () => editor.chain().focus().toggleOrderedList().run(),
        })}

        {@render control({
          icon: "link-horizontal",
          active: linkActive,
          disabled: !linkEnabled,
          onclick: setLink,
        })}
      {/if}
    {/key}
  </div>

  <div
    bind:this={element}
    class="max-h-48 w-full overflow-y-scroll border-t border-st-300 px-4 py-3 [&_p.is-editor-empty:first-child::before]:pointer-events-none [&_p.is-editor-empty:first-child::before]:float-left [&_p.is-editor-empty:first-child::before]:h-0 [&_p.is-editor-empty:first-child::before]:text-st-500 [&_p.is-editor-empty:first-child::before]:content-[attr(data-placeholder)]"
  ></div>
</Field>

{#snippet control({ icon, active, ...rest })}
  <button
    class={cn(
      "flex size-6 items-center justify-center rounded-md border border-st-300 transition-colors hover:text-accent disabled:text-st-300",
      active && "border-accent text-accent",
    )}
    {...rest}
  >
    <Icon type={icon} class="size-3" />
  </button>
{/snippet}
