<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { SECTION_LINKS } from "$lib/const";
  import { cn } from "$lib/utils";
  import Icon from "$lib/components/Icon.svelte";
  import BrowserButton from "./BrowserButton.svelte";
  import UpdateButton from "./UpdateButton.svelte";
  import DesignPreview from "$lib/components/DesignPreview.svelte";
  import Page from "../layouts/Page.svelte";

  const SECTION = $derived(SECTION_LINKS.find(({ href }) => href === "/design"));

  let { design, children } = $props();

  let isFullscreen = $state(false);
  let isDesktopSupported = $state(false);
  let isDesktop = $state(false);
  let isMobile = $derived(!isFullscreen && !isDesktop);
  let isEditing = $state(false);
  let isUpdated = $state(design.updated);
  let version = $state(design.version);
  let previewUrl = $state(design.preview_url);

  const onFullscreen = () => {
    isFullscreen = true;
    isDesktop = false;
  };

  const onDesktop = () => {
    isFullscreen = false;
    isDesktop = true;
  };

  const onMobile = () => {
    isFullscreen = false;
    isDesktop = false;
  };

  const onResize = () => {
    isDesktopSupported = window.innerWidth >= 1024;
    isDesktop = isDesktopSupported && isDesktop;
  };

  onMount(() => {
    onResize();
    isDesktop = isDesktopSupported;
  });

  $effect(() => {
    version = design.version;
    isUpdated = design.updated;
    previewUrl = design.preview_url;
  });

  $effect(() => {
    if (isUpdated && version) {
      window.dispatchEvent(new CustomEvent("preview:refresh"));
    }
  });

  $effect(() => {
    previewUrl;
    isEditing = false;
  });
</script>

<svelte:window onresize={onResize} />

<Page>
  <div class={cn("flex flex-1 flex-col items-center justify-center", isFullscreen && "absolute inset-0 z-50 h-[100dvh] w-[100dvw]")}>
    <div
      class={cn(
        "flex flex-1 flex-col overflow-hidden border-st-950 bg-st-50 dark:border-st-400",
        (isDesktop || isFullscreen) && "w-full",
        !isDesktop && !isFullscreen && "h-full w-full sm:max-h-[675px] sm:w-[380px]",
        !isFullscreen && "rounded-xl border shadow-lg",
      )}
    >
      <header class="flex flex-none items-center justify-between border-b border-st-950 p-2 dark:border-st-400">
        <div class="ml-3 hidden items-center gap-2 text-st-500 lg:flex">
          <Icon type={SECTION.iconProps.type} class="size-4" />
          <h1 class="truncate font-mono uppercase">{SECTION.text}</h1>
        </div>

        <div class="flex gap-0">
          <BrowserButton onclick={onFullscreen} isActive={isFullscreen} iconProps={{ type: "expand", class: "rotate-90" }} />

          {#if isDesktopSupported}
            <BrowserButton onclick={onDesktop} isActive={isDesktop} iconProps={{ type: "desktop" }} />
          {/if}

          <BrowserButton onclick={onMobile} isActive={isMobile} iconProps={{ type: "mobile" }} />
        </div>

        <button
          onclick={() => (isEditing = !isEditing)}
          class={cn(
            "flex w-[83px] items-center justify-between gap-2 rounded-lg py-1.5 pl-2.5 pr-3 text-accent transition-colors hover:bg-st-200",
            isEditing && "bg-st-950 text-st-50 hover:bg-st-800",
          )}
        >
          {#if isEditing || !isUpdated}
            <Icon type="pen" class="size-3.5" />
          {:else}
            <span class="ml-1 mt-px size-2 animate-pulse rounded-full bg-accent"></span>
          {/if}

          <span class="font-mono uppercase">Edit</span>
        </button>
      </header>

      <div class="relative flex min-h-0 flex-1">
        <div class="flex flex-1 flex-col">
          <DesignPreview url={previewUrl} class="flex-1" />
        </div>

        {#if isEditing}
          <div
            transition:fly={{ x: 370, opacity: 0.9, duration: 300 }}
            class={cn("absolute bottom-2 right-2 top-2 flex flex-none flex-col gap-2", isDesktop || (isDesktopSupported && isFullscreen) ? "sm:w-80" : "w-[calc(100%-16px)]")}
          >
            <div class="flex-1 overflow-y-auto rounded-md bg-st-50/80 shadow-2xl saturate-150 backdrop-blur-lg">
              {@render children()}
            </div>

            <div class="flex flex-none gap-2">
              <UpdateButton text="Discard" href="/design" method="delete" confirm="You will lose all unsaved changes." disabled={!isUpdated} iconProps={{ type: "trash" }} />

              <UpdateButton
                text="Publish"
                href="/design/promote"
                method="patch"
                disabled={!isUpdated}
                iconProps={{ type: "arrow-up-from-line" }}
                class="bg-accent hover:bg-[color-mix(in_srgb,rgb(124_68_252),white_20%)] disabled:bg-st-500/70"
              />
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</Page>
