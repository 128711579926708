<script>
  import { page } from "@inertiajs/svelte";
  import DesignPreview from "$lib/components/DesignPreview.svelte";
  import SVG from "$lib/components/SVG.svelte";
  import deviceSVG from "$assets/images/posts/device.svg?raw";

  let { preview_url } = $props();
  let { posts } = $derived($page.props);

  $effect(() => {
    posts;
    window.dispatchEvent(new CustomEvent("preview:refresh"));
  });
</script>

<div class="hidden max-h-full min-h-[700px] min-w-[355px] basis-1/2 items-center justify-center rounded-md bg-st-200 px-8 lg:flex">
  <div class="relative w-full min-w-[423px] scale-[.8]">
    <SVG src={deviceSVG} width={305} height={660} class="absolute inset-auto size-full opacity-20 dark:invert" />

    <div class="ml-[2.8%] aspect-[9/16] size-full skew-y-[-8deg] scale-x-[.8] px-[24px] pb-[88px] pt-[100px]">
      <DesignPreview url={preview_url} class="size-full max-h-[600px] rounded-lg" />
    </div>
  </div>
</div>
