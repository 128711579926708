<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import TemplateImage from "$lib/components/TemplateImage.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Link from "$lib/components/Link.svelte";

  let { name, font, platform_icons, template, plan_description, referral_description } = $props();
</script>

<svelte:head>
  <title>New Site Summary - Supertape</title>
</svelte:head>

<Page backHref="/sites/new/plans" buttonText="Create your site" buttonHref="/sites/new/create" buttonMethod="post">
  <Header title="Let's do this" description="All that's left is to create your site. Let's go!" />

  <div class="divide-y divide-st-300 rounded-md bg-st-50">
    {#snippet artistContent()}
      <p class={`font-${font} text-3xl leading-tight`}>{name}</p>
    {/snippet}

    {@render section({ title: "Artist", children: artistContent })}

    {#snippet platformsContent()}
      {#if platform_icons.length}
        <ul class="flex flex-wrap gap-2 pb-1.5 sm:pb-0">
          {#each platform_icons as icon}
            <li>
              <Icon type={icon} class="size-6" />
            </li>
          {/each}
        </ul>
      {:else}
        <p class="font-mono uppercase">Connect after setup</p>
      {/if}
    {/snippet}

    {@render section({ title: "Platforms", children: platformsContent })}

    {#snippet templateContent()}
      <TemplateImage {template} priority />
    {/snippet}

    {@render section({ title: "Template", subtitle: template, children: templateContent })}

    {#snippet planContent()}
      <p class="font-mono uppercase">{plan_description}</p>
    {/snippet}

    {@render section({ title: "Plan", children: planContent })}

    {#if referral_description}
      {#snippet referralContent()}
        <p class="font-mono uppercase">{referral_description}</p>
      {/snippet}

      {@render section({ title: "Referral", children: referralContent })}
    {/if}

    {#snippet legalContent()}
      <p class="text-sm">
        By continuing you confirm you're <Link href="https://help.supertape.com/legal/acceptable-use-policy" class="text-accent transition-opacity hover:opacity-50"
          >authorized</Link
        > to act on behalf of {name}.
      </p>
    {/snippet}

    {@render section({ title: "Legal", children: legalContent })}
  </div>
</Page>

{#snippet section({ title, subtitle, children })}
  <div class="flex flex-col gap-2 px-4 py-3 sm:px-6 sm:py-[18px]">
    {#if subtitle}
      <div class="flex items-center justify-between pb-1">
        {@render sectionHeader({ title })}
        <p class="font-mono text-xs">{subtitle}</p>
      </div>
    {:else}
      {@render sectionHeader({ title })}
    {/if}

    {@render children()}
  </div>
{/snippet}

{#snippet sectionHeader({ title })}
  <h3 class="font-poppins-medium text-xs tracking-wide text-st-500">{title}</h3>
{/snippet}
