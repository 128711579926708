<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { PRICING_URL } from "$lib/const";
  import Page from "./Page.svelte";
  import Header from "./Header.svelte";
  import PlanPicker from "$lib/components/PlanPicker.svelte";
  import Link from "$lib/components/Link.svelte";

  let props = $props();
</script>

<svelte:head>
  <title>New Site Plan - Supertape</title>
</svelte:head>

<Page backHref="/sites/new/presets">
  <Header title="Select a plan" />

  <div class="flex flex-1 flex-col justify-between gap-8">
    <PlanPicker {...props} planProps={{ class: "bg-st-300" }} />
    <Link href={PRICING_URL} class="mb-8 block flex-none text-center text-st-500 transition-colors hover:text-accent">Compare plans</Link>
  </div>
</Page>
