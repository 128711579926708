<script>
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { subject_types } = $props();
</script>

<Modal title="New Post">
  <StackList>
    {#each subject_types as subject_type}
      <StackListItem as="Link" href={subject_type.href} target="_modal">
        {#snippet image()}
          <FramedIcon iconProps={{ type: subject_type.icon }} />
        {/snippet}

        {#snippet title()}
          {subject_type.name}
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
