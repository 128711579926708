<script module>
  export { default as layout } from "./Layout.svelte";
</script>

<script>
  import { useForm } from "@inertiajs/svelte";
  import Page from "./Page.svelte";
  import Form from "$lib/components/Form.svelte";

  let { name } = $props();
  const form = useForm({ name });
</script>

<svelte:head>
  <title>New Site - Supertape</title>
</svelte:head>

<Page buttonHref="/sites/new/profiles" hideAvatar hideBackground>
  <Form {form} method="post" action="/sites/new/profiles" class="flex size-full items-center justify-center">
    <input
      bind:value={$form.name}
      type="text"
      placeholder="What's your artist name?"
      class="font-poppins-medium w-full bg-transparent text-center text-[7vw] tracking-tighter placeholder:text-st-400 focus:outline-none sm:text-[40px]"
      autocomplete="off"
      data-1p-ignore
      required
    />
  </Form>
</Page>
