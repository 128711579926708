<script>
  import Index from "$lib/components/inventory/Index.svelte";
  import Grid from "$lib/components/inventory/Grid.svelte";

  let { videos, ...props } = $props();
</script>

<Index resources={videos} {...props}>
  <Grid resources={videos} imageProps={{ width: 496, height: 279 }} class="sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2" />
</Index>
