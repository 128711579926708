<script>
  import InfoAlert from "$lib/components/InfoAlert.svelte";
  import Plaque from "$lib/components/Plaque.svelte";
  import Unlock from "$lib/components/Unlock.svelte";

  const UNLOCK_POSTERS = import.meta.glob("$assets/images/plans/pro_*.jpg", { eager: true });
  const UNLOCK_VIDEOS = import.meta.glob("$assets/videos/plans/pro_*.mp4", { eager: true });

  let { collection, enabled, has_platforms, has_page, has_resources, resources, children } = $props();

  let unlockPosterUrl = $derived(UNLOCK_POSTERS[`/images/plans/pro_${collection.name.toLowerCase()}.jpg`]?.default);
  let unlockVideoUrl = $derived(UNLOCK_VIDEOS[`/videos/plans/pro_${collection.name.toLowerCase()}.mp4`]?.default);
</script>

{#if enabled}
  {#if !has_page}
    <InfoAlert href="/design" text={`The ${collection.name} page is hidden in your design`} iconProps={{ type: "eye-slash" }} class="mb-2.5" />
  {/if}

  {#if has_platforms}
    {#if resources.length}
      {@render children(resources)}
    {:else}
      <Plaque
        title={`No ${collection.id}`}
        description={has_resources ? `We couldn't find any ${collection.id} matching your filters` : `We haven't found any ${collection.id} on your platforms yet`}
        iconProps={{ type: collection.icon }}
      />
    {/if}
  {:else}
    <Plaque
      title={`No ${collection.name.toLowerCase()} platforms`}
      description={`Connect your ${collection.name.toLowerCase()} platforms to have ${collection.id} synced`}
      iconProps={{ type: collection.icon }}
      linkProps={{ href: "/platforms" }}
    />
  {/if}
{:else}
  <Unlock
    title={`Unlock ${collection.name} with Pro`}
    description={`Upgrade now to have your ${collection.id} added to your website`}
    videoUrl={unlockVideoUrl}
    posterUrl={unlockPosterUrl}
  />
{/if}
