<script>
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { platforms } = $props();
</script>

<Modal title="New Platform" leftAction="Back">
  <StackList>
    {#each platforms as { name, icon, action_icon, ...linkProps }}
      <StackListItem as="Link" {...linkProps}>
        {#snippet image()}
          <FramedIcon iconProps={{ type: icon }} />
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet action()}
          <Icon type={action_icon} class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
