<script>
  import { humanize, cn, mx } from "$lib/utils";

  let { form, name, label, noLabel = false, hint, noContainer = false, containerProps, labelProps, children, ...props } = $props();

  label ||= humanize(name);

  let error = $derived($form.errors[name] ? `${label} ${$form.errors[name]}` : null);

  let propsMx = $derived(mx({ class: "group/field" }, props));

  let containerPropsMx = $derived(
    mx(
      {
        class: cn(
          "w-full px-4 py-3 rounded-md bg-st-200 flex items-center justify-between gap-4",
          error ? "ring-1 ring-error" : "group-has-[:focus]/field:ring-1 group-has-[:focus]/field:ring-accent",
        ),
      },
      containerProps,
    ),
  );

  let labelPropsMx = $derived(
    mx(
      {
        class: cn("whitespace-nowrap", error ? "text-error group-has-[:focus]/field:text-error" : "text-st-950/60 group-has-[:focus]/field:text-accent"),
      },
      labelProps,
    ),
  );
</script>

<div {...propsMx}>
  {#if noContainer}
    {@render children()}
  {:else}
    <div {...containerPropsMx}>
      {#if !noLabel}
        <label for={name} {...labelPropsMx}>
          {@html label}
        </label>
      {/if}

      {@render children()}
    </div>
  {/if}

  {#if error}
    <div class="mt-1 font-mono text-xs text-error">
      {@html error}
    </div>
  {/if}

  {#if hint}
    <div class="group/hint mb-3 ml-0.5 mt-1 font-mono text-xs text-st-500 group-last/hint:last:mb-0 group-[has(+_button)]/hint:last:mb-0 group-has-[+_button]/hint:last:mb-0">
      {@html hint}
    </div>
  {/if}
</div>
