<script>
  import ActionButton from "$lib/components/ActionButton.svelte";
  import Rive from "$lib/components/Rive.svelte";

  let { title, description, videoUrl, posterUrl, riveUrl } = $props();
</script>

<div class="grain flex justify-center rounded-md bg-st-950 py-16 text-st-50 sm:py-24 dark:bg-st-200 dark:text-st-950">
  <div class="max-w-sm">
    <h2 class="font-poppins-medium text-balance text-center text-[40px] leading-[46px] tracking-tighter sm:text-5xl">{title}</h2>

    {#if riveUrl}
      <Rive src={riveUrl} width={310} height={500} class="mx-auto" />
    {:else if videoUrl}
      <video src={videoUrl} poster={posterUrl} class="mx-auto mb-10 mt-8 h-[495px] w-[240px] rounded-[38px]" autoplay playsinline muted loop></video>
    {/if}

    <p class="mt-2 text-center font-mono uppercase leading-tight tracking-wide">{description}</p>

    <ActionButton
      href="/billing/plan"
      target="_modal"
      class="mx-auto mt-10 flex w-min items-center justify-center rounded-xl border border-accent-dark px-8 py-3 text-accent-dark transition-colors hover:border-accent hover:bg-accent hover:text-white"
      iconProps={{ type: "st-lock-sm", class: "size-4" }}
    />
  </div>
</div>
