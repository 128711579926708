<script>
  import { cn, mx } from "$lib/utils";

  const SIZES = {
    base: "text-xs px-2 py-1 rounded-md",
    sm: "text-[10px] text-st-500 px-1 py-px rounded",
  };

  let { size = "base", children, ...props } = $props();
  let sizeClass = $derived(SIZES[size]);
</script>

<span {...mx({ class: cn("font-mono uppercase bg-st-300", sizeClass) }, props)}>
  {@render children()}
</span>
