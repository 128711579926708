<script>
  import { Switch } from "bits-ui";
  import { mx } from "$lib/utils";
  import Field from "../Field.svelte";

  let { form, name, items, fieldProps, ref = $bindable(null), ...props } = $props();

  let propsMx = $derived(
    mx(
      {
        id: name,
        name,
        class:
          "relative inline-flex w-10 py-1 rounded-full outline-none transition-colors focus-visible:outline-none disabled:opacity-50 data-[state=checked]:bg-accent data-[state=unchecked]:bg-st-300",
        onCheckedChange: () => {
          ref.closest("form").dispatchEvent(new Event("change"));
        },
      },
      props,
    ),
  );
</script>

<Field {form} {name} {...fieldProps}>
  <Switch.Root bind:ref bind:checked={$form[name]} {...propsMx}>
    <Switch.Thumb class="pointer-events-none size-4 rounded-full bg-white shadow-md transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-1" />
  </Switch.Root>
</Field>
