<script>
  import { Image } from "@unpic/svelte";
  import { cn } from "$lib/utils";
  import Icon from "$lib/components/Icon.svelte";
  import InfoSnippet from "$lib/components/InfoSnippet.svelte";
  import Info from "./Info.svelte";

  let { name, imageUrl, isVideo = false, meta, info, infoPlaceholder } = $props();
</script>

<div class="gap-2.5 lg:grid lg:grid-cols-12">
  <div class="flex flex-col items-center justify-center gap-4 px-10 pb-8 pt-6 lg:col-span-6 lg:rounded-md lg:bg-st-200 lg:px-20 lg:pb-12 lg:pt-16 xl:aspect-square">
    {#if imageUrl}
      <Image src={imageUrl} alt={name} width={600} height={isVideo ? 338 : 600} class="rounded-md shadow-2xl" />
    {:else}
      <div class={cn("flex w-full items-center justify-center rounded-md border border-st-300", isVideo ? "aspect-video" : "aspect-square")}>
        <InfoSnippet text="No image" iconProps={{ type: "xmark" }} />
      </div>
    {/if}

    <h1 class="text-balance text-center text-3xl leading-tight tracking-[-0.03em]">{name}</h1>
  </div>

  <div class="flex h-full flex-col gap-2.5 lg:col-span-6">
    <dl class="grid grid-cols-2 rounded-md bg-st-200 lg:h-1/2 lg:grid-cols-6">
      {#each meta as { label, value, icon }, index}
        <div class={cn("flex flex-col justify-between gap-4 border-st-300 px-4 py-3 lg:col-span-3 lg:pt-4", index % 2 === 0 && "border-r", index < 2 && "border-b")}>
          <Icon type={icon} class="hidden size-4 lg:block" />

          <div>
            <dt class="font-poppins-medium mb-0.5 text-xs tracking-wide text-st-500">{label}</dt>
            <dd class="truncate font-mono text-sm uppercase lg:text-base">{value}</dd>
          </div>
        </div>
      {/each}
    </dl>

    <div class="flex h-1/2 flex-col justify-between gap-4 rounded-md bg-st-200 p-4 py-3">
      <h2 class="lg:font-poppins-medium text-xl tracking-wide lg:text-xs lg:text-st-500">Info</h2>
      <Info {info} placeholder={infoPlaceholder} />
    </div>
  </div>
</div>
