<script>
  import { cn } from "$lib/utils";

  let { title, description } = $props();
</script>

<header class={cn("mx-auto my-4 mb-8 max-w-sm flex-none space-y-4 text-center sm:my-8", description ? "sm:mb-16" : "sm:mb-12")}>
  <h1 class="font-poppins-medium text-3xl tracking-tighter sm:text-[40px]">{title}</h1>

  {#if description}
    <p class="text-pretty font-mono uppercase leading-tight text-st-500 sm:text-base">{description}</p>
  {/if}
</header>
