<script>
  import { Dialog } from "bits-ui";
  import { mx } from "$lib/utils";
  import NavBar from "./NavBar.svelte";
  import ActionLink from "./ActionLink.svelte";

  let { title, leftAction, rightAction, isWorking = false, children, ...props } = $props();
</script>

<NavBar>
  {#snippet leftAction()}
    {#if typeof leftAction === "function"}
      {@render leftAction({ isWorking })}
    {:else if leftAction?.toLowerCase() === "back"}
      <ActionLink text="Back" {isWorking} onclick={() => history.back()} iconProps={{ type: "chevron-left" }} />
    {:else}
      <Dialog.Close>
        {#snippet child({ props: closeProps })}
          <ActionLink text="Close" {isWorking} iconProps={{ type: "xmark-large" }} {...closeProps} />
        {/snippet}
      </Dialog.Close>
    {/if}
  {/snippet}

  {#snippet title()}
    <Dialog.Title>{title}</Dialog.Title>
  {/snippet}

  {#snippet rightAction()}
    {@render rightAction?.({ isWorking })}
  {/snippet}
</NavBar>

<div {...mx({ class: "w-screen sm:max-w-md p-4 sm:p-6" }, props)}>
  {@render children()}
</div>
