<script>
  import SVG from "$lib/components/SVG.svelte";
  import wordmarkSVG from "$assets/images/wordmark.svg?raw";
  import Form from "$lib/components/Form.svelte";
  import InfoCopy from "../InfoCopy.svelte";

  let { form, method, action, button = "Submit", footer, children } = $props();
</script>

<div class="-mt-24 w-full max-w-md space-y-10">
  <SVG src={wordmarkSVG} />

  <Form {form} {method} {action}>
    {@render children()}

    <button type="submit" class="block min-h-12 w-full rounded-md bg-st-950 px-4 py-3 text-st-50 transition-colors hover:bg-accent hover:text-white">
      {button}
    </button>
  </Form>

  {#if footer}
    <InfoCopy>
      {@render footer()}
    </InfoCopy>
  {/if}
</div>
