<script>
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { options } = $props();
</script>

<Modal title="New Domain">
  <StackList>
    {#each options as { name, icon, href, description }}
      <StackListItem as="Link" {href} target="_modal">
        {#snippet image()}
          <FramedIcon iconProps={{ type: icon }} />
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet subtitle()}
          {description}
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
