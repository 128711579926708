<script>
  import { fade } from "svelte/transition";
  import { router } from "@inertiajs/svelte";

  let showProgress = $state(false);

  let onStart = () => (showProgress = true);
  let onFinish = () => (showProgress = false);

  $effect(() => router.on("start", onStart));
  $effect(() => router.on("finish", onFinish));
</script>

{#if showProgress}
  <div in:fade={{ delay: 800, duration: 200 }} out:fade={{ duration: 200 }} class="fixed left-0 top-0 z-[999999] h-1 w-full overflow-hidden bg-accent/30">
    <div class="animate-indeterminate-progress h-full origin-[0%_50%] bg-accent"></div>
  </div>
{/if}

<style>
  @keyframes indeterminate-progress {
    0% {
      transform: translateX(0) scaleX(0);
    }

    40% {
      transform: translateX(0) scaleX(0.4);
    }

    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }

  .animate-indeterminate-progress {
    animation: indeterminate-progress 1s infinite linear;
  }
</style>
