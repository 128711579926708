<script>
  import Grid from "./Grid.svelte";
  import Plaque from "$lib/components/Plaque.svelte";

  let { site_links } = $props();
</script>

{#if site_links.length}
  <Grid {site_links} />
{:else}
  <Plaque
    title="Platforms"
    description="Connect your platform profiles to sync your music, merch, videos, tour dates, and more"
    iconProps={{ type: "layer-group" }}
    linkProps={{ href: "/platforms/new", target: "_modal", iconProps: { type: "plus" } }}
  />
{/if}
