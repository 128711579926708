<script>
  import { page } from "@inertiajs/svelte";
  import { GLOBAL_LINKS, SECTION_LINKS, REFERRAL_LINK } from "$lib/const";
  import { mx } from "$lib/utils";
  import Menu from "$lib/components/Menu.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Avatar from "$lib/components/Avatar.svelte";
  import NavLinks from "./NavLinks.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import ActionButton from "$lib/components/ActionButton.svelte";
  import BillingStatus from "./BillingStatus.svelte";

  let { section } = $props();
</script>

<aside class="fixed left-0 top-0 flex h-[100dvh] w-56 flex-col gap-4 overflow-y-auto p-8">
  <header class="flex-none">
    <Menu items={GLOBAL_LINKS} contentProps={{ align: "start", class: "w-60" }}>
      <div class="flex items-center gap-2">
        <Avatar name={$page.props.current.site.name} imageProps={{ src: $page.props.current.site.image_url }} />
        <Icon type="chevron-down" class="size-4" />
      </div>
    </Menu>
  </header>

  <nav class="mt-[62px] flex-1 space-y-8">
    <NavLinks title="Promote" links={SECTION_LINKS.slice(0, 4)} active={section} />
    <NavLinks title="Inventory" links={SECTION_LINKS.slice(4, 8)} active={section} />
    <NavLinks title="Customize" links={SECTION_LINKS.slice(8, 11)} active={section} />
  </nav>

  <footer class="flex-none space-y-2">
    <ActionLink {...mx(REFERRAL_LINK, { iconProps: { type: "badge-dollar", dir: "solid" } })} class="font-sans text-sm normal-case text-st-500" />

    {#if $page.props.current.user.owner}
      <BillingStatus />
    {/if}

    <ActionButton
      text="View site"
      href={$page.props.current.site.url}
      target="_blank"
      class="w-full justify-between rounded-md border-accent py-1.5 pl-2 pr-1.5 text-sm tracking-wide text-accent"
      iconProps={{ type: "arrow-up-right", class: "size-[18px]" }}
    />
  </footer>
</aside>
