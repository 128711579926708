<script>
  import Modal from "$lib/components/Modal.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { undo_href, message } = $props();
</script>

<Modal title="Plan">
  {#snippet rightAction({ isWorking })}
    <ActionLink {isWorking} text="Undo" href={undo_href} method="delete" confirm iconProps={{ type: "arrow-rotate-left" }} />
  {/snippet}

  <div class="grain flex flex-col items-center justify-center space-y-3.5 text-balance rounded-xl bg-st-200 p-6 text-center">
    <Icon type="circle-exclamation" class="size-5 text-error" />
    <p>{@html message}</p>
  </div>
</Modal>
