import { createInertiaApp } from "@inertiajs/svelte";
import { mount } from "svelte";
import { VIEWS } from "$lib/const";
import Page from "$layouts/Page.svelte";

createInertiaApp({
  progress: false,
  resolve: (name) => {
    const view = VIEWS[`../${name}.svelte`];
    return { default: view.default, layout: view.layout || Page };
  },
  setup({ el, App, props }) {
    mount(App, { target: el, props });
  },
});
