<script>
  import Plan from "./Plan.svelte";
  import Payment from "./Payment.svelte";
  import Address from "./Address.svelte";

  let { site, plan, plan_actions, payment_actions, address_actions } = $props();
</script>

<div id="billing" class="grid gap-0 divide-y divide-st-300 rounded-md bg-st-200 sm:grid-cols-12 sm:divide-x sm:divide-y-0">
  <Plan {site} {plan} actions={plan_actions} />
  <Payment payment_method={site.payment_method} actions={payment_actions} />
  <Address billing_address={site.billing_address} actions={address_actions} />
</div>
