<script>
  import { Image } from "@unpic/svelte";
  import Modal from "$lib/components/Modal.svelte";
  import ShareAction from "$lib/components/ShareAction.svelte";

  let { name, type, url, qrcode_url, image_url, image_aspect_ratio = "1/1", icon_props } = $props();
  let isVideo = $derived(image_aspect_ratio === "16/9");
</script>

<Modal title="Share">
  <div class="space-y-4">
    <div class="flex flex-col items-center justify-center">
      {#if image_url}
        <Image src={image_url} alt={name} width={isVideo ? 284 : 160} height={160} class="rounded-md shadow-xl" />
      {/if}

      <h2 class="mt-4 text-center text-2xl">{name}</h2>
      <p class="font-mono text-sm text-st-500">{type}</p>
    </div>

    <ShareAction label={name} {url} qrcodeUrl={qrcode_url} iconProps={icon_props} />
  </div>
</Modal>
