<script>
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { tools } = $props();
</script>

<Modal title="New Tool">
  <StackList>
    {#each tools as { name, icon, href, disabled }}
      <StackListItem as="Link" {href} {disabled} target="_modal">
        {#snippet image()}
          <FramedIcon iconProps={{ type: icon }} />
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet action()}
          <Icon type={disabled ? "check" : "chevron-right"} class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
