<script>
  import { parameterize } from "$lib/utils";
  import Modal from "$lib/components/Modal.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Time from "$lib/components/Time.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { sessions } = $props();
</script>

<Modal title="Sign Out">
  {#snippet rightAction()}
    <ActionLink text="All" href="/sessions" method="delete" confirm iconProps={{ type: "arrow-right-from-line" }} />
  {/snippet}

  <StackList>
    {#each sessions as session}
      <StackListItem as="Link" href={`/sessions/${session.id}`} method="delete" target="_modal" confirm>
        {#snippet image()}
          <FramedIcon iconProps={{ type: parameterize(session.browser) }} />
        {/snippet}

        {#snippet title()}
          {session.browser} on {session.os}
        {/snippet}

        {#snippet subtitle()}
          {#if session.current}
            <span class="animate-pulse text-accent">Current session</span>
          {:else}
            <span>Session active </span>
            <Time datetime={session.updated_at} format="timeago" />
          {/if}
        {/snippet}

        {#snippet action()}
          <Icon type="arrow-right-from-line" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
