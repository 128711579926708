<script module>
  export { default as layout } from "../designs/Layout.svelte";
</script>

<script>
  import NavBar from "$lib/components/NavBar.svelte";
  import ActionLink from "$lib/components/ActionLink.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import Icon from "$lib/components/Icon.svelte";
  import Plaque from "$lib/components/Plaque.svelte";

  let { site_links, custom_link_enabled } = $props();
</script>

<NavBar title="New Link">
  {#snippet leftAction({ isWorking })}
    <ActionLink text="Back" href="/design" {isWorking} iconProps={{ type: "chevron-left" }} />
  {/snippet}
</NavBar>

<div class="p-4">
  {#if !site_links.length}
    <Plaque
      size="base"
      title="No platforms connected"
      description="Connect platforms to link to them in your footer"
      iconProps={{ type: "layer-group" }}
      linkProps={{ href: "/platforms" }}
      class="mb-1 py-8"
    />
  {/if}

  <StackList>
    {#each site_links as { id, name, icon, linked } (id)}
      <StackListItem
        as="Link"
        href="/design/links"
        method="post"
        data={{ design_link: { target: "footer", subject_type: "SiteLink", subject_id: id, name, icon } }}
        disabled={linked}
      >
        {#snippet image()}
          <Icon type={icon} class="size-4" />
        {/snippet}

        {#snippet title()}
          {name}
        {/snippet}

        {#snippet action()}
          <Icon type={linked ? "check" : "plus"} class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}

    {#if custom_link_enabled}
      <StackListItem as="Link" href="/design/links/new/custom">
        {#snippet image()}
          <Icon type="globe" class="size-4" />
        {/snippet}

        {#snippet title()}
          Custom
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {:else}
      <StackListItem as="Link" href="/billing/plan" target="_modal">
        {#snippet image()}
          <Icon type="globe" class="size-4" />
        {/snippet}

        {#snippet title()}
          Custom
        {/snippet}

        {#snippet action()}
          <Icon type="st-lock-sm" class="size-4" />
        {/snippet}
      </StackListItem>
    {/if}
  </StackList>
</div>
