<script module>
  export { default as layout } from "$layouts/Iso.svelte";
</script>

<script>
  import { useForm } from "@inertiajs/svelte";
  import IsoForm from "$lib/components/form/IsoForm.svelte";
  import InputField from "$lib/components/form/fields/InputField.svelte";

  const form = useForm({
    confirmation_code: null,
  });
</script>

<svelte:head>
  <title>Login - Supertape</title>
  <meta name="description" content="Sign in to your Supertape band website to manage your music, merch, tour, videos, design, and more." />
</svelte:head>

<IsoForm {form} method="post" action="/sessions" button="Confirm">
  <InputField {form} name="confirmation_code" type="code" fieldProps={{ label: "Emailed code" }} required autofocus />

  {#snippet footer()}
    We've emailed you a temporary confirmation code. Check your inbox and junk folder.
  {/snippet}
</IsoForm>
