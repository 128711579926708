<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoHeader from "$lib/components/InfoHeader.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";
  import InfoList from "$lib/components/InfoList.svelte";
  import Time from "$lib/components/Time.svelte";

  let { site_link, platform } = $props();
  let isManuallySyncable = $derived(new Date(site_link.manually_syncable_at) < new Date());
  let rightActionProps = $derived(isManuallySyncable ? { text: "Sync", iconProps: { type: "arrows-rotate" } } : false);

  const form = useForm({});
</script>

<FormModal {form} title="Sync Platform" action={`/platforms/${site_link.id}/sync`} method="patch" {rightActionProps} class="space-y-8" submitOptions={{ preserveScroll: true }}>
  <InfoHeader title={platform.name} iconProps={{ type: platform.icon }} />

  <InfoCopy>In addition to our automatic nightly sync, you can also manually sync a platform once an hour.</InfoCopy>

  <InfoList
    items={[
      { label: "Last synced", value: syncedAt },
      { label: "Sync available", value: manuallySyncableAt },
    ]}
  />
</FormModal>

{#snippet syncedAt()}
  <Time datetime={site_link.synced_at} format="timeago" />
{/snippet}

{#snippet manuallySyncableAt()}
  {#if isManuallySyncable}
    now
  {:else}
    <Time datetime={site_link.manually_syncable_at} format="timeago" />
  {/if}
{/snippet}
