<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";
  import InfoList from "$lib/components/InfoList.svelte";
  import InfoCopy from "$lib/components/InfoCopy.svelte";

  const form = useForm({});
  let { title, action, stripeProps, items, message } = $props();
</script>

<FormModal {title} {form} {action} {stripeProps} method="patch" leftAction="Back" class="space-y-4">
  <InfoList {items} />

  {#if message}
    <InfoCopy>{@html message}</InfoCopy>
  {/if}
</FormModal>
