<script>
  import { Image } from "@unpic/svelte";
  import { humanizeUrl } from "$lib/utils";
  import Icon from "$lib/components/Icon.svelte";
  import ShareAction from "$lib/components/ShareAction.svelte";

  let { name, url, image_url, qrcode_url } = $props();
</script>

<section class="relative flex flex-col overflow-hidden rounded-md bg-st-800 px-4 pb-5 pt-3 text-white lg:px-6 lg:pt-[18px]">
  {#if image_url}
    <Image src={image_url} alt="Cover image" width={1024} height={452} class="absolute inset-0 size-full scale-150 object-cover opacity-70 blur-xl" priority />
    <div class="grain absolute inset-0 z-10 size-full bg-st-50/30"></div>
  {/if}

  <div class="z-20 flex items-center justify-between gap-4 font-mono uppercase">
    <h3>{name}</h3>

    <div class="flex items-center gap-1">
      <Icon type="arrow-turn-right" class="size-4" />
      <span>Share</span>
    </div>
  </div>

  <div class="z-20 flex grow flex-col items-center justify-center gap-[min(4vw,64px)] pt-12 lg:flex-row lg:px-4 lg:pb-24 lg:pt-20">
    <div class="w-11/12 max-w-xs overflow-hidden rounded-xl bg-st-50 shadow-xl lg:w-[280px]">
      {#if image_url}
        <Image src={image_url} alt="Cover image" width={280} aspectRatio="16/9" priority />
      {:else}
        <div class="flex aspect-video w-full items-center justify-center bg-st-950/20">
          <Icon type="xmark" class="size-5 text-st-950/40" />
        </div>
      {/if}

      <div class="px-4 py-3">
        <div class="text-xs tracking-wide text-st-950">
          {name}
        </div>

        <div class="truncate whitespace-nowrap font-mono text-xs tracking-wide text-st-500">
          {humanizeUrl(url)}
        </div>
      </div>
    </div>

    <div class="w-full max-w-md p-4 lg:p-0">
      <h2 class="mb-1 ml-px text-2xl tracking-tight">Share your site</h2>

      <p class="mb-6 ml-px leading-snug opacity-70 mix-blend-color-dodge">
        Copy your URL into your social profiles' link-in-bio to get more visitors and connect fans to your latest releases.
      </p>

      <ShareAction
        label={url}
        {url}
        qrcodeUrl={qrcode_url}
        buttonProps={{ class: "bg-white/20 py-4" }}
        copyProps={{ class: "text-white" }}
        qrButtonProps={{ class: "bg-white/20" }}
      />
    </div>
  </div>
</section>
