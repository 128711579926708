<script>
  import InfoAlert from "$lib/components/InfoAlert.svelte";
  import Meta from "$lib/components/detail/Meta.svelte";
  import Tracks from "./Tracks.svelte";
  import Products from "./Products.svelte";
  import Links from "$lib/components/detail/Links.svelte";

  let { album } = $props();
</script>

<div class="space-y-2.5">
  {#if album.hidden}
    <InfoAlert text="This album is hidden" iconProps={{ type: "eye-slash" }} />
  {/if}

  <Meta name={album.name} imageUrl={album.image_url} meta={album.meta} info={album.info} infoPlaceholder="Add a description, credits, accolades, etc." />

  <Tracks tracks={album.tracks} />

  {#if album.products.length}
    <Products products={album.products} />
  {/if}

  <Links links={album.links} />
</div>
