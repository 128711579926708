<script>
  import { useForm } from "@inertiajs/svelte";
  import FormModal from "$lib/components/modal/FormModal.svelte";

  let { src } = $props();
  let origin = $derived(new URL(src).origin);
  let formRef = $state();

  const form = useForm({
    name: null,
  });

  let onmessage = (event) => {
    if (event.origin !== origin || !event.data || !event.data.length) return;

    $form.name = event.data
      .filter((item) => item.item_category === "registration")
      .map((item) => `${item.item_name}:${item.item_id}`)
      .join(",");

    formRef.submit();
  };
</script>

<svelte:window {onmessage} />

<FormModal {form} bind:formRef title="Name.com Search" action="/domains" method="post" leftAction="Back" rightActionProps={false}>
  <iframe {src} title="Domain search" class="h-[100dvh] max-h-full w-full overflow-hidden rounded-md bg-[#EBEBEB] lg:max-h-[600px]"></iframe>
</FormModal>
