<script>
  import { mx, commaNumber, humanizeNumber } from "$lib/utils";
  import Icon from "$lib/components/Icon.svelte";
  import DoughnutChart from "./DoughnutChart.svelte";
  import Delta from "$lib/components/Delta.svelte";

  let { type, count, delta, platforms, ...props } = $props();

  let title = $derived.by(() => {
    if (type === "albums") return "Music";
    if (type === "events") return "Tour";
    if (type === "products") return "Merch";
    if (type === "videos") return "Videos";
    if (type === "newsletters") return "Newsletters";
    return "Social";
  });
</script>

<div {...mx({ class: "col-span-1 flex flex-col justify-between border-b border-st-300 p-4 pt-3 last:border-b-0" }, props)}>
  <h2 class="font-poppins-medium text-xs tracking-wide text-st-500">{title}</h2>

  <div class="relative flex aspect-square flex-col items-center justify-center p-8 sm:p-2">
    {#if platforms.length}
      <div title={commaNumber(count)} class="text-3xl tracking-tight lg:text-2xl min-[1140px]:text-3xl">
        {humanizeNumber(count)}
      </div>
      <Delta {delta} />
    {:else}
      <Icon type="empty-set" class="size-4 text-st-500" />
    {/if}

    <DoughnutChart
      metricSingular="follower"
      metricPlural="followers"
      labels={platforms.map(({ name }) => name)}
      values={platforms.map(({ count }) => count)}
      colors={platforms.map(({ color }) => color)}
      class="absolute"
    />
  </div>
</div>
