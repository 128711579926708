<script>
  import { Image } from "@unpic/svelte";
  import Modal from "$lib/components/Modal.svelte";
  import StackList from "$lib/components/StackList.svelte";
  import StackListItem from "$lib/components/StackListItem.svelte";
  import FramedIcon from "$lib/components/FramedIcon.svelte";
  import Icon from "$lib/components/Icon.svelte";

  let { subjects } = $props();
</script>

<Modal title="New Post" leftAction="Back">
  <StackList>
    {#each subjects as subject}
      <StackListItem as="Link" href={subject.href} target="_modal">
        {#snippet image()}
          {#if subject.image_url}
            <Image src={subject.image_url} alt={subject.name} width={48} height={48} class="size-12 rounded-md" />
          {:else}
            <FramedIcon iconProps={{ type: subject.icon }} />
          {/if}
        {/snippet}

        {#snippet title()}
          {subject.name}
        {/snippet}

        {#snippet action()}
          <Icon type="chevron-right" class="size-4" />
        {/snippet}
      </StackListItem>
    {/each}
  </StackList>
</Modal>
