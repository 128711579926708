export const elementsOptions = {
  currency: "usd",
  loader: "never",
  appearance: {
    theme: "flat",
    variables: {
      colorPrimary: "#7C44FC",
      colorBackground: document.documentElement.classList.contains("dark") ? "#262626" : "#E5E5E5",
      colorText: document.documentElement.classList.contains("dark") ? "#fafafa" : "#0a0a0a",
      colorDanger: "#ef4444",
      fontFamily: "Poppins, system-ui, sans-serif",
      fontWeightLight: "400",
      fontWeightNormal: "400",
      fontWeightMedium: "400",
      fontWeightBold: "400",
      fontVariationSettings: "normal",
      fontVariantLigatures: "normal",
      fontSmooth: "always",
      spacingUnit: "4px",
      borderRadius: "8px",
    },
    rules: {
      ".Label": {
        color: document.documentElement.classList.contains("dark") ? "#fafafa" : "#0a0a0a",
        marginTop: "6px",
        marginBottom: "7px",
        marginLeft: "1px",
        fontSize: "16px",
      },
      ".Input": {
        fontSize: "16px",
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: document.documentElement.classList.contains("dark") ? "#262626" : "#E5E5E5",
      },
      ".Input::placeholder": {
        color: document.documentElement.classList.contains("dark") ? "#666666" : "#A3A3A3",
      },
      ".Block, .PickerItem--selected, .PickerItem--selected:hover": {
        boxShadow: "none",
      },
      ".Error, .TermsText": {
        fontFamily: "IBM Plex Mono, system-ui, sans-serif",
        fontSize: "14px",
        lineHeight: "20px",
      },
      ".TermsText": {
        textColor: "#737373",
      },
    },
  },
  fonts: [
    { cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap" },
    { cssSrc: "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400&display=swap" },
  ],
};
